import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const PaymentMdl = ({
  user,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setAmount,
  setPaid,
  setNotes,
  handleClose,
  handlePayment,
  show,
}) => {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Form width="100%" onSubmit={handlePayment}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-money"></i> Add Payment - {user.firstname}{" "}
            {user.lastname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="paymentForm.email">
            <Form.Label>
              <i className="fa fa-info-circle-o"></i>
              {user.institution ? " Institution Name" : "  Email address"}
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="name@example.com"
              value={user.email ? user.email : ""}
              //   onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </Form.Group>

          <Form.Group controlId="paymentForm.from">
            <Form.Label>
              <i className="fa fa-calendar"></i> From
            </Form.Label>
            <br></br>
            <DatePicker
              name="date_from"
              showPopperArrow={true}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group>
          <Form.Group controlId="paymentForm.to">
            <Form.Label>
              <i className="fa fa-calendar"></i> To
            </Form.Label>
            <br></br>
            <DatePicker
              name="date_to"
              showPopperArrow={true}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group>
          <Form.Group controlId="amount">
            <Form.Label>
              <i className="fa fa-money"></i> Amount
            </Form.Label>
            <Form.Control
              type="text"
              name="amount"
              value={user.amount ? user.amount : ""}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="paymentForm.paid">
            <Form.Label>
              <i className="fa fa-money"></i> Paid
            </Form.Label>
            <Form.Control
              type="text"
              name="paid"
              value={user.paid}
              onChange={(e) => setPaid(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="paymentForm.notes">
            <Form.Label>
              <i className="fa fa-comment"></i> Notes
            </Form.Label>
            <Form.Control
              type="text"
              name="notes"
              value={user.notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button variant="primary" onClick={handlePayment}>
            <i className="fa fa-disc"></i> Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PaymentMdl;
