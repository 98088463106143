const store = require("store");
const _ = require("underscore");

var checkCookies = (function () {
  var dismiss = false,
    existingdismissTokens = "";
  var getCookies = function () {
    if (!_.isUndefined(store.get("cookies_tokens"))) {
      existingdismissTokens =
        !_.isNull(store.get("cookies_tokens")) &&
        store.get("cookies_tokens") !== ""
          ? JSON.parse(store.get("cookies_tokens"))
          : null;
    }
    if (existingdismissTokens) {
      dismiss = !_.isUndefined(existingdismissTokens.dismiss)
        ? existingdismissTokens.dismiss
        : false;
    }
    return dismiss; // Or pull this from cookie/localStorage
  };

  var setCookies = function (val) {
    store.set("cookies_tokens", JSON.stringify({ dismiss: val }));
    dismiss = val;
    // Also set this in cookie/localStorage
  };

  return {
    getCookies: getCookies,
    setCookies: setCookies,
  };
})();

export default checkCookies;
