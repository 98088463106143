import React, { useState, useEffect } from "react";

import { Badge, Form, Row, Col, Button, Alert, Card } from "react-bootstrap";
import _ from "underscore";
// import { loadStripe } from "@stripe/stripe-js";
import Sidebar from "../components/SidebarRegistration";
import { useAuth } from "./../context/auth";
import { updateTokens } from "./../context/checkTokens";

const queryString = require("query-string");

const axios = require("axios");

const Checkoutsuccess = (props) => {
  const [hasError, setErrors] = useState(false);
  const [session, setSession] = useState({});
  const { authTokens, setAuthTokens } = useAuth();

  const params = queryString.parse(props.location.search);
  const sessionId =
    params.session_id && !_.isEmpty(params.session_id) ? params.session_id : "";

  useEffect(() => {
    document.body.classList.remove("homepage");
    if (!_.isUndefined(props.isPro) ? props.isPro : false)
      document.body.classList.add("pro-user");
    else document.body.classList.add("free-user");
    async function fetchSession() {
      setSession(
        await axios
          .get(
            !_.isUndefined(process.env.REACT_APP_API_URL)
              ? process.env.REACT_APP_API_URL + "/Auth/Subscriptionsuccess"
              : "/Auth/Subscriptionsuccess",
            { params: { sessionId: sessionId } },
            { withCredentials: true }
          )
          .then((res) => {
            document.body.classList.add("pro-user");
            setAuthTokens(updateTokens(authTokens, "isPro", "1"));

            return res.data;
          })
      );
    }
    fetchSession();
  }, [sessionId]);
  //

  return (
    <main role="main" className="main mt-2">
      <Row></Row>
      {hasError ? (
        <Alert variant="danger">Request Failed.Access Denied!</Alert>
      ) : (
        <Row>
          <Col md="3">
            <Sidebar {...props} />
          </Col>
          <Col md="6">
            {/* {loading ? (
              <div className="pull-center">
                <Spinner animation="border" variant="success" />
              </div>
            ) : ( */}
            <Card>
              <Card.Body>
                <Card.Header className="text-success">
                  <h2>
                    <i className="fa fa-check-circle"></i> Payment Status
                  </h2>
                </Card.Header>

                <div className="sr-section completed-view">
                  <div className="sr-callout">
                    <Alert variant="success">
                      Payment submitted successfully for Trip{" "}
                      <span className="pro-label"> PRO </span> account. Your
                      unique payment reference is{" "}
                      <strong> {session ? session.payment_intent : ""} </strong>
                      . You will recieve a confirmation email on the email
                      address provided {session ? session.customer_email : ""}.
                      You should now be able use the Trip{" "}
                      <span className="pro-label"> PRO </span> features.
                      <br></br>
                      <br></br>{" "}
                      <Button href="/Home" className="btn btn-info">
                        <i className="fa fa-home"></i> Continue to Trip
                      </Button>
                    </Alert>
                  </div>
                </div>
              </Card.Body>
            </Card>
            {/* )} */}
          </Col>
        </Row>
      )}
    </main>
  );
};

export default Checkoutsuccess;
