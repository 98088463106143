import _ from "underscore";
const store = require("store");
/**
 * checkTokens from Storage
 */
export function checkTokensStorage() {
  var isPro = false,
    isLogin = false,
    institution_id = null,
    institution_name = null,
    existingTokens = "",
    existingInstitutions = "",
    oa_login = false;

  if (!_.isUndefined(store.get("tokens"))) {
    existingTokens =
      !_.isNull(store.get("tokens")) && store.get("tokens") !== ""
        ? JSON.parse(store.get("tokens"))
        : null;
  }
  if (!_.isUndefined(store.get("instution_auth_tokens"))) {
    existingInstitutions = !_.isUndefined(store.get("instution_auth_tokens"))
      ? store.get("instution_auth_tokens")
      : "";

    if (!_.isUndefined(existingInstitutions.institution_auth)) {
      if (!_.isNull(existingInstitutions.institution_auth)) {
        institution_id = existingInstitutions.institution_auth.id;
        institution_name =
          existingInstitutions.institution_auth.institution_shortname;
        if (!_.isUndefined(existingInstitutions.institution_auth.oa_login)) {
          oa_login = existingInstitutions.institution_auth.oa_login;
        }
      }
    }
  }

  if (existingTokens) {
    var getdata = _.findWhere(existingTokens.roles, {
      role: "Pro",
    });
    if (getdata) {
      isPro = true;
    }
    if (!_.isUndefined(existingTokens.loggedin))
      isLogin = existingTokens.loggedin;
  }

  return {
    chkPro: isPro,
    chkLogin: isLogin,
    chkInstitution: {
      institution_id: institution_id,
      institution_name: institution_name,
      oa_login: oa_login,
    },
  };
}
/**
 * checkTokens from context
 * @param {*} authTokens
 */
export function checkTokens(authTokens) {
  var isPro = false,
    isLogin = false;
  if (authTokens) {
    var getdata = _.findWhere(authTokens.roles, {
      role: "Pro",
    });
    if (getdata) {
      isPro = true;
    }
    if (!_.isUndefined(authTokens.loggedin)) isLogin = authTokens.loggedin;
  }

  return { chkPro: isPro, chkLogin: isLogin };
}

export function updateTokens(authTokens, param, value) {
  var tokens = "";
  if (authTokens) {
    tokens = authTokens;
    tokens[param] = value;
  }
  return tokens ? tokens : authTokens;
}
