import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 7.16 9.55"
  >
    <polygon
      points="7.17 4.78 0 0 0 9.55 7.17 4.78"
      fill="#b3b3b3"
      fillRule="evenodd"
    ></polygon>
  </svg>
);
