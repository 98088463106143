export async function wayfless(w, a, y, f, appId) {
  try {
    w._wayfinder =
      w._wayfinder ||
      function () {
        (w._wayfinder.q = w._wayfinder.q || []).push(arguments);
      };
    var p = {
      oaDomain: "tripdatabase.com",
      oaAppId: appId, //"7ca0328c-55c6-4bfe-b851-0e1b82b9bd5b",
      //  oaAppId: "c405b315-2492-403b-a8af-d279e7d52798", //Live site
    };
    w._wayfinder.settings = p;
    var h = a.getElementsByTagName("head")[0];
    let s = a.createElement("script");

    s.async = 1;
    var q = Object.keys(p)
      .map(function (key) {
        return key + "=" + p[key];
      })
      .join("&");
    s.src = y + "v1" + f + "?" + q;
    h.appendChild(s);
  } catch (ex) {
    console.log("wayfayless embedding function error");
  }
}
