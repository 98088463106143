import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col } from "react-bootstrap";
import Sidebar from "../components/SidebarRegistration";
import { useAuth } from "./../context/auth";
import { checkTokensStorage } from "../context/checkTokens";
import { wayfless } from "./../context/wayfless";

// import LoginForm from "../components/LoginForm";
import ErrorMdl from "../components/errorMdl";
const OA = (props) => {
  var loginObj = checkTokensStorage();

  /**
   * on page load/update
   */
  // const wayfless = async (w, a, y, f, appId) => {
  //   w._wayfinder =
  //     w._wayfinder ||
  //     function () {
  //       (w._wayfinder.q = w._wayfinder.q || []).push(arguments);
  //     };
  //   var p = {
  //     oaDomain: "tripdatabase.com",
  //     oaAppId: appId,
  //     //  oaAppId: "c405b315-2492-403b-a8af-d279e7d52798", //Live site
  //   };
  //   w._wayfinder.settings = p;
  //   var h = a.getElementsByTagName("head")[0];
  //   const s = a.createElement("script");
  //   s.async = 1;
  //   var q = Object.keys(p)
  //     .map(function (key) {
  //       return key + "=" + p[key];
  //     })
  //     .join("&");
  //   s.src = y + "v1" + f + "?" + q;
  //   h.appendChild(s);
  // };
  useEffect(() => {
    document.title = "Login - Trip Medical Database";
    document.body.classList.remove("homepage");
    wayfless(
      window,
      document,
      "https://wayfinder.openathens.net/embed/",
      "/loader.js",
      // "7ca0328c-55c6-4bfe-b851-0e1b82b9bd5b"
      "c405b315-2492-403b-a8af-d279e7d52798"
    );
  }, []);
  return (
    <main role="main" className="main mt-2">
      <Row></Row>
      <Row>
        <Col md="3">{/* <Sidebar {...props} /> */}</Col>
        <Col md="6">
          <Row>
            <Col>
              <div className="card">
                <div className="card-body">
                  <div className="card-header">
                    <h2>Institution Login</h2>
                  </div>
                  <br />

                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        {/* <span className="input-group-text" id="prepend-email">
                          <i className="fa fa-university"></i>
                        </span> */}
                      </div>
                      <div>
                        <a
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          className="wayfinder-login btn btn-primary"
                        >
                          <i className="fa fa-university"></i> Institution login
                        </a>
                      </div>
                      <br />

                      {loginObj.chkInstitution.institution_name && (
                        <div>
                          {" "}
                          <div
                            className="mb-4 alert alert-secondary"
                            role="alert"
                          >
                            {loginObj.chkInstitution.institution_name}{" "}
                            <a className="alert-link" href="/Logout">
                              Logout?
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default OA;
