const store = require("store");
const _ = require("underscore");

var checkDismiss = (function () {
  var dismiss = false,
    existingdismissTokens = "";
  var getDismiss = function () {
    if (!_.isUndefined(store.get("dismiss_tokens"))) {
      existingdismissTokens =
        !_.isNull(store.get("dismiss_tokens")) &&
        store.get("dismiss_tokens") !== ""
          ? JSON.parse(store.get("dismiss_tokens"))
          : null;
    }
    if (existingdismissTokens) {
      dismiss = !_.isUndefined(existingdismissTokens.dismiss)
        ? existingdismissTokens.dismiss
        : false;
    }
    return dismiss; // Or pull this from cookie/localStorage
  };

  var setDismiss = function (val) {
    store.set("dismiss_tokens", JSON.stringify({ dismiss: val }));
    dismiss = val;
    // Also set this in cookie/localStorage
  };

  return {
    getDismiss: getDismiss,
    setDismiss: setDismiss,
  };
})();

export default checkDismiss;
