import React from "react";

export default (data) => (
  <>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.55 15.55"
    >
      <g>
        <polygon
          points="4.7 3.33 5.55 3.19 5.29 1.11 4.44 1.25 4.7 3.33"
          fill="#b3b3b3"
        ></polygon>
        <polygon
          points="3.19 5.55 3.33 4.7 1.25 4.44 1.11 5.29 3.19 5.55"
          fill="#b3b3b3"
        ></polygon>
        <rect
          x="2.83"
          y="2.26"
          width="1"
          height="2.14"
          transform="translate(-1.38 3.33) rotate(-44.98)"
          fill="#b3b3b3"
        ></rect>
        <polygon
          points="10.85 12.22 10 12.36 10.26 14.44 11.11 14.3 10.85 12.22"
          fill="#b3b3b3"
        ></polygon>
        <polygon
          points="12.36 10 12.22 10.85 14.3 11.11 14.44 10.26 12.36 10"
          fill="#b3b3b3"
        ></polygon>
        <rect
          x="11.72"
          y="11.15"
          width="1"
          height="2.14"
          transform="translate(-5.06 12.22) rotate(-45.01)"
          fill="#b3b3b3"
        ></rect>
        <path
          d="M14.45,1.1a3.78,3.78,0,0,1,0,5.34L12,8.89,11.11,8l2.45-2.45A2.52,2.52,0,1,0,10,2L7.56,4.44l-.89-.89L9.11,1.1A3.78,3.78,0,0,1,14.45,1.1Z"
          fill="#b3b3b3"
        ></path>
        <path
          d="M8.89,12,6.46,14.44A3.78,3.78,0,1,1,1.11,9.09L3.54,6.67l.89.89L2,10a2.52,2.52,0,0,0,3.57,3.57L8,11.13Z"
          fill="#b3b3b3"
        ></path>
      </g>
    </svg>
    {data.text ? data.text : ""}
  </>
);
