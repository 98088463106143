import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13.61 15.81"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          x="-171.45"
          y="239.88"
          width="666.55"
          height="385.12"
          fill="none"
        />
      </clipPath>
    </defs>
    <g>
      <path
        d="M9.88,2.53l2.65,2.63H9.88ZM2.52,2.38H9.06V6h3.69v9H2.52Zm-.85-.76V15.81H13.61V5.16L10,1.62Z"
        fill="#b3b3b3"
        fillRule="evenodd"
      />
      <polygon
        points="9.21 0.79 8.05 0 0 0 0 14.36 0.8 14.36 0.8 0.79 9.21 0.79"
        fill="#b3b3b3"
        fillRule="evenodd"
      />
    </g>
    <g>
      <path d="M11.74,8.36V7.88H9.62l.23.48Z" fill="#b3b3b2" />
      <path d="M3.57,7.88v.48H5.45c.07-.16.15-.32.23-.48Z" fill="#b3b3b2" />
      <path d="M11.74,9.82V9.33h-1.5l.15.49Z" fill="#b3b3b2" />
      <path d="M3.57,9.33v.49H4.92c0-.16.09-.32.14-.49Z" fill="#b3b3b2" />
      <path
        d="M10.56,11.27h1.18v-.48H10.56c0,.08,0,.16,0,.24S10.56,11.19,10.56,11.27Z"
        fill="#b3b3b2"
      />
      <path
        d="M4.75,10.79H3.57v.48H4.76a1.94,1.94,0,0,1,0-.24C4.74,11,4.75,10.87,4.75,10.79Z"
        fill="#b3b3b2"
      />
      <path d="M11.74,12.73v-.49H10.3a2.6,2.6,0,0,1-.28.49Z" fill="#b3b3b2" />
      <path d="M3.57,12.24v.49H5.29A2.6,2.6,0,0,1,5,12.24Z" fill="#b3b3b2" />
      <path
        d="M6.49,13.7H3.57v.49h8.17V13.7H8.82a2.95,2.95,0,0,1-2.33,0Z"
        fill="#b3b3b2"
      />
      <rect x="3.57" y="3.51" width="4.62" height="0.49" fill="#b3b3b2" />
      <path d="M3.57,5v.48H7.14L7.48,5Z" fill="#b3b3b2" />
      <path d="M8.2,5.45V5H7.82l.35.48Z" fill="#b3b3b2" />
      <path d="M11.74,6.91V6.42H8.8l.29.49Z" fill="#b3b3b2" />
      <path d="M3.57,6.42v.49H6.2l.3-.49Z" fill="#b3b3b2" />
    </g>
    <path
      d="M7.66,5.68S5.19,8.82,5.19,11a2.47,2.47,0,1,0,4.93,0C10.12,8.89,7.66,5.68,7.66,5.68Zm0,6.48a1.2,1.2,0,0,1-1.17-1.29,3.22,3.22,0,0,1,1.3-2.73c-.74.76-.62,2.59.07,2.59s.45-1,.24-1.23A1.65,1.65,0,0,1,8.84,11,1.15,1.15,0,0,1,7.65,12.16Z"
      fill="#ef8118"
    />
  </svg>
);
