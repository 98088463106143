import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from "underscore";
import ArrowRtSvg from "../assets/svg/ArrowRt";
import DotSecondaryEvidenceSvg from "../assets/svg/DotSecondaryEvidence";
import DotPrimarySvg from "../assets/svg/DotPrimary";
import DotClinicalSvg from "../assets/svg/DotClinical";
import DotControlledSvg from "../assets/svg/DotControlledTrails";
import DotSystematicSvg from "../assets/svg/DotSystematic";

const ResultCategories = ({
  isPro,
  isInstitutionPro = {},
  categories_set,
  categories_set_child,
  categories_pro,
  clinical_area_set,
  refinements_set,
  current_selections,
  loading,
  catToggle,
  from_date,
  to_date,
  categorySelect,
  clinicalareaSelect,
  refinementSelect,
  setCatToggle,
  setfromDate,
  settoDate,
  dateRangeSelect,
}) => {
  // Fire this if loading
  if (loading) {
    return <div>Processing results...</div>; //<div className="spinner-border text-info"></div>;
  }

  var menu_builder1 = _.where(categories_set, { quality: 1 });
  var menu_builder2 = _.where(categories_set, { quality: 2 });
  var menu_builder3 = _.where(categories_set, { quality: 3 });
  var menu_builder4 = _.where(categories_set, { quality: 4 });
  var menu_builder5 = _.where(categories_set, { quality: 5 });
  var category_pro = _.where(categories_pro, { category_id: 11 });
  var category_pro_count = !_.isUndefined(category_pro[0])
    ? category_pro[0].count
    : 0;
  // Populate children categories count
  setChildrenCategories();
  function setChildrenCategories() {
    for (let i = 0; i <= _.size(menu_builder1) - 1; i++) {
      if (_.size(menu_builder1[i].children) > 0) {
        for (let j = 0; j <= _.size(menu_builder1[i].children) - 1; j++) {
          var get_count = _.findWhere(categories_set_child, {
            id: menu_builder1[i].children[j].id,
          });
          if (!_.isUndefined(get_count))
            menu_builder1[i].children[j].count = get_count.count;
          // for (let cat of categories_set_child) {
          //   if (menu_builder1[i].children[j].id === cat.id)
          //     menu_builder1[i].children[j].count = cat.count;
          // }
        }
      }
    }
    return;
  }

  return (
    <div className="filter-results">
      {/* <h6 className="text-muted">Filter Results</h6> */}
      <div className="filter-results--group">
        <div className="filter-results--group--intro">
          <h5 className="text-dark">Filter Results</h5>

          <div className="evidence-types-action-links">
            <div className="form-check form-check-inline">
              {/* <div className="form-check form-check-inline"></div> */}
              <input
                name="advfrom"
                placeholder=" FROM"
                size="4"
                type="text"
                className="form-check-inline form-control"
                // value={from_date}
                onChange={(e) => setfromDate(e.target.value)}
              />
            </div>
            <div className="form-check form-check-inline">
              {/* <div className="form-check form-check-inline">-</div> */}
              <input
                name="advto"
                placeholder=" TO"
                size="4"
                type="text"
                className="form-check-inline form-control"
                // value={to_date}
                onChange={(e) => settoDate(e.target.value)}
              />

              <a
                href="#"
                type="submit"
                className="form-check form-check-inline btn btn-sm btn-primary badge-primary"
                size="sm"
                onClick={(e) => {
                  dateRangeSelect(e);
                  e.preventDefault();
                }}
              >
                Apply
              </a>
            </div>
          </div>
        </div>
        {/* All Secondary Evidence start */}
        <ul className="evidence-type-group sub-group-visible">
          {menu_builder1.map((element) => (
            <li
              className={
                current_selections.currentCategory == element.query_category
                  ? "sub-group-visible"
                  : "sub-group-visible"
              }
              key={element.id}
            >
              <button
                onClick={(e) => categorySelect(e, element.query_category)}
                className={
                  current_selections.currentCategory == element.query_category
                    ? "toggle-dot js-toggle-dot toggle-dot--evidence-secondary is-active"
                    : current_selections.currentCategory
                    ? "toggle-dot js-toggle-dot toggle-dot--evidence-secondary "
                    : "toggle-dot js-toggle-dot toggle-dot--evidence-secondary is-active"
                }
              >
                <DotSecondaryEvidenceSvg />
              </button>{" "}
              <button
                className={
                  current_selections.currentCategory == element.query_category
                    ? "badge badge-evidence-secondary is-active"
                    : current_selections.currentCategory
                    ? "badge badge-evidence-secondary"
                    : "badge badge-evidence-secondary is-active"
                }
                onClick={(e) => categorySelect(e, element.query_category)}
              >
                {element.category_name}
                <span className="badge-count">
                  {" "}
                  {element.children.length == 0
                    ? element.id == 11 && isPro !== true
                      ? element.count - category_pro_count
                      : element.count
                    : ""}{" "}
                </span>
              </button>
              {/* Remove if collapse cat not needed */}
              {element.children.length > 0 && (
                <button
                  className="btn-reveal js-evidence-type-reveal"
                  onClick={(e) => setCatToggle(!catToggle)}
                >
                  <ArrowRtSvg />
                </button>
              )}
              {catToggle && (
                <ul className="tree">
                  {element.children.map((child_element) => (
                    <li className="" key={child_element.id}>
                      <button
                        className={
                          current_selections.currentCategory == child_element.id
                            ? "badge badge-evidence-secondary is-active"
                            : current_selections.currentCategory
                            ? "badge badge-evidence-secondary"
                            : "badge badge-evidence-secondary is-active"
                        }
                        // onClick={(e) => categorySelect(e, child_element.id)}

                        onClick={(e) => {
                          if (child_element.prouser !== 1)
                            categorySelect(e, child_element.id);
                          else if (child_element.prouser === 1 && isPro)
                            categorySelect(e, child_element.id);
                        }}
                      >
                        {child_element.category_name}
                        {/* {findChildcount(child_element.id)} */}
                        <span className="badge-count">
                          {child_element.count}
                        </span>
                      </button>{" "}
                      {child_element.prouser === 1 ? (
                        <span className="pro-label">Pro</span>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        {/* Secondary evidence end */}
        {/* Primary Evidence Start */}
        <ul className="evidence-type-group sub-group-visible">
          {menu_builder2.map((element) => (
            <li key={element.id}>
              <button
                className={
                  current_selections.currentCategory == element.id
                    ? "toggle-dot js-toggle-dot toggle-dot--evidence-key-primary"
                    : "toggle-dot js-toggle-dot toggle-dot--evidence-key-primary is-active"
                }
                onClick={(e) => categorySelect(e, element.id)}
              >
                <DotPrimarySvg />
              </button>{" "}
              <button
                className={
                  current_selections.currentCategory == element.id
                    ? "badge badge-evidence-key-primary is-active"
                    : current_selections.currentCategory
                    ? "badge badge-evidence-key-primary"
                    : "badge badge-evidence-key-primary is-active"
                }
                onClick={(e) => categorySelect(e, element.id)}
              >
                {element.category_name}{" "}
                <span className="badge-count">
                  {element.children.length == 0 ? element.count : ""}
                </span>
              </button>
              {element.children.length > 0 && (
                <button
                  className="btn-reveal js-evidence-type-reveal"
                  onClick={(e) => setCatToggle(!catToggle)}
                >
                  <ArrowRtSvg />
                </button>
              )}
              {catToggle && (
                <ul className="tree">
                  {element.children.map((child_element) => (
                    <li
                      className=""
                      key={child_element.id}
                      style={{ color: "black" }}
                    >
                      <button
                        className={
                          current_selections.currentCategory == child_element.id
                            ? "badge badge-evidence-primary is-active"
                            : current_selections.currentCategory
                            ? "badge badge-evidence-key-primary"
                            : "badge badge-evidence-key-primary is-active"
                        }
                        onClick={(e) => categorySelect(e, child_element.id)}
                      >
                        {child_element.category_name}{" "}
                        <span className="badge-count">
                          {child_element.count}
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        {/* End Primary Evidence */}
        {/* Start Clinical */}
        <ul className="evidence-type-group sub-group-visible">
          {menu_builder3.map((element) => (
            <li key={element.id}>
              <button
                className={
                  current_selections.currentCategory == element.id
                    ? "toggle-dot js-toggle-dot toggle-dot--evidence-clinical-q-and-a"
                    : "toggle-dot js-toggle-dot toggle-dot--evidence-clinical-q-and-a is-active"
                }
                onClick={(e) => categorySelect(e, element.id)}
              >
                <DotClinicalSvg />
              </button>{" "}
              <button
                className={
                  current_selections.currentCategory == element.id
                    ? "badge badge-evidence-clinical-q-and-a is-active"
                    : current_selections.currentCategory
                    ? "badge badge-evidence-clinical-q-and-a"
                    : "badge badge-evidence-clinical-q-and-a is-active"
                }
                onClick={(e) => categorySelect(e, element.id)}
              >
                {element.category_name}{" "}
                <span className="badge-count">
                  {element.children.length == 0 ? element.count : ""}
                </span>
              </button>
              {/* {element.children.length > 0 && (
                <button className="btn-reveal js-evidence-type-reveal">
                  <ArrowRtSvg />
                </button>
              )} */}
              <ul className="tree">
                {element.children.map((child_element) => (
                  <li className="" key={child_element.id}>
                    <button
                      className={
                        current_selections.currentCategory == child_element.id
                          ? "badge badge-evidence-clinical-q-and-a is-active"
                          : current_selections.currentCategory
                          ? "badge badge-evidence-clinical-q-and-a"
                          : "badge badge-evidence-clinical-q-and-a is-active"
                      }
                      onClick={(e) => categorySelect(e, child_element.id)}
                    >
                      {child_element.category_name}
                      <span className="badge-count">{child_element.count}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        {/* End Clinical Evidence */}
        {/* Start Controlled Trails */}
        <ul className="evidence-type-group sub-group-visible">
          {menu_builder4.map((element) => (
            <li key={element.id} className="sub-group-visible">
              <button
                className={
                  current_selections.currentCategory == element.id
                    ? "toggle-dot js-toggle-dot toggle-dot--evidence-all-primary"
                    : "toggle-dot js-toggle-dot toggle-dot--evidence-all-primary is-active"
                }
                onClick={(e) => categorySelect(e, element.id)}
              >
                <DotControlledSvg />
              </button>{" "}
              <button
                className={
                  current_selections.currentCategory == element.id
                    ? "badge badge-evidence-all-primary sub-group-visible is-active"
                    : current_selections.currentCategory
                    ? "badge badge-evidence-all-primary sub-group-visible"
                    : "badge badge-evidence-all-primary sub-group-visible is-active"
                }
                onClick={(e) => categorySelect(e, element.id)}
              >
                {element.category_name}{" "}
                <span className="badge-count">
                  {element.count > 0 ? element.count : ""}
                </span>
              </button>
              {element.children.length > 0 && (
                <button
                  className="btn-reveal js-evidence-type-reveal"
                  onClick={(e) => setCatToggle(!catToggle)}
                >
                  <ArrowRtSvg />
                </button>
              )}
              {catToggle && (
                <ul className="tree">
                  {element.children.map((child_element) => (
                    <li className="" key={child_element.id}>
                      <button
                        className={
                          current_selections.currentCategory == child_element.id
                            ? "badge badge-evidence-all-primary is-active"
                            : current_selections.currentCategory
                            ? "badge badge-evidence-all-primary"
                            : "badge badge-evidence-all-primary is-active"
                        }
                        onClick={(e) => categorySelect(e, child_element.id)}
                      >
                        {child_element.category_name}
                        <span className="badge-count">
                          {child_element.count}
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        {/* End Controlled Trails */}
        {/* Start Systematic Reviews */}
        <ul className="evidence-type-group sub-group-visible">
          {menu_builder5.map((element) => (
            <li key={element.id} className="sub-group-visible">
              <button
                className={
                  current_selections.currentCategory == element.query_category
                    ? "toggle-dot js-toggle-dot toggle-dot--evidence-other"
                    : "toggle-dot js-toggle-dot toggle-dot--evidence-other is-active"
                }
                onClick={(e) => {
                  if (element.prouser !== 1)
                    categorySelect(e, element.query_category);
                  else if (element.prouser === 1 && isPro)
                    categorySelect(e, element.query_category);
                }}
              >
                <DotSystematicSvg />
              </button>{" "}
              <button
                className={
                  current_selections.currentCategory == element.query_category
                    ? "badge badge-evidence-other is-active"
                    : current_selections.currentCategory
                    ? "badge badge-evidence-other"
                    : "badge badge-evidence-other is-active"
                }
                //Code for Pro Categories
                onClick={(e) => {
                  if (element.prouser !== 1)
                    categorySelect(e, element.query_category);
                  else if (element.prouser === 1 && isPro)
                    categorySelect(e, element.query_category);
                }}
              >
                {element.category_name}
                <span className="badge-count">
                  {element.count > 0 ? element.count : ""}
                </span>
              </button>{" "}
              {element.prouser === 1 ? (
                <span className="pro-label">Pro</span>
              ) : (
                ""
              )}
              {element.children.length > 0 && (
                <button
                  className="btn-reveal js-evidence-type-reveal"
                  onClick={(e) => setCatToggle(!catToggle)}
                >
                  <ArrowRtSvg />
                </button>
              )}
              {catToggle && (
                <ul className="tree">
                  {element.children.map((child_element) => (
                    <li className="" key={child_element.id}>
                      <button
                        className={
                          current_selections.currentCategory == child_element.id
                            ? "badge badge-evidence-other is-active"
                            : current_selections.currentCategory
                            ? "badge badge-evidence-other"
                            : "badge badge-evidence-other is-active"
                        }
                        onClick={(e) => categorySelect(e, child_element.id)}
                      >
                        {child_element.category_name}{" "}
                        <span className="badge-count">
                          {child_element.count}
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        {/* End Systematic Reviews */}
        <hr></hr>
      </div>

      <div className="filter-results--group filter-results--group__checkboxes">
        <h5 className="text-dark">
          Clinical Area{" "}
          {isPro || !_.isEmpty(isInstitutionPro) ? (
            ""
          ) : (
            <span className="pro-label">Pro</span>
          )}
        </h5>
        {(isPro || !_.isEmpty(isInstitutionPro)) && (
          <ul className="">
            {clinical_area_set.map((element, idx) => (
              <li key={idx + "clinicalli"}>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={idx + "clinical"}
                    onChange={(e) => clinicalareaSelect(e, element.area)}
                    checked={current_selections.currentArea === element.area}
                    disabled={!(isPro || !_.isEmpty(isInstitutionPro))}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={idx + "clinical"}
                  >
                    {element.area}{" "}
                  </label>{" "}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      <hr></hr>
      <div className="filter-results--group filter-results--group__checkboxes">
        <h5 className="text-dark">Further Refinement</h5>
        <ul className="">
          {/* grade 5 */}
          {refinements_set.map((element, idx) => (
            <li key={idx}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  tooltip="tool tip"
                  id={idx + "ref"}
                  onChange={(e) => {
                    refinementSelect(e, element.type, element.value);
                  }}
                  checked={
                    current_selections.currentfromDate === element.value ||
                    current_selections.currentTag === element.value
                  }
                />
                <OverlayTrigger
                  key="top"
                  placement="top"
                  className="pro-tooltip"
                  overlay={
                    <Tooltip id={`tooltip-top-bookmark-refine` + idx}>
                      {!_.isUndefined(element.description)
                        ? element.description
                        : "Filter"}
                    </Tooltip>
                  }
                >
                  <label className="custom-control-label" htmlFor={idx + "ref"}>
                    {element.name}
                  </label>
                </OverlayTrigger>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default ResultCategories;
