import React, { useState, useEffect, useMemo } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Dropdown,
  Table,
  Breadcrumb,
  Form,
  Button,
  Card,
} from "react-bootstrap";
//import styled from "styled-components";
import DataTable from "react-data-table-component";
// import SidebarAdmin from "../../../components/SidebarAdmin";
import ErrorMdl from "../../../components/errorMdl";
import DeleteMdl from "../../../components/deleteMdl";

import "../../../components/assets/css/Tabs.css";
//import { orderBy } from "lodash";
import EditSEOMdl from "../EditSEOMdl";
// import PaymentMdl from "../PaymentMdl";
// const dateFormat = require("dateformat");
const axios = require("axios");
const _ = require("underscore");
const { customStyles } = require("../tableStyle");

const SEO = (props) => {
  //  Modal.setAppElement(".edt-modal");
  let pub_list = ["Publish", "Unpublish"];

  const [show, setShow] = useState(false);
  const [paymentshow, setPaymentShow] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [deleteshow, setDeleteShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [lookupdata, setLookupData] = useState([]);
  const [deletedata, setDeleteData] = useState([]);
  const [deleteaction, setDeleteAction] = useState(1);

  const [userstype, setUsersType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentid, setID] = useState(0);
  var futuredate = new Date();
  futuredate.setFullYear(futuredate.getFullYear() + 1);
  const [title, setTitle] = useState("");
  const [route, setRoute] = useState("");
  const [topicheading1, setTopicHeading1] = useState("");
  const [topic1, setTopic1] = useState("");
  const [topicheading2, setTopicHeading2] = useState("");
  const [topic2, setTopic2] = useState("");
  const [keywords, setKeywords] = useState("");
  const [meta, setMeta] = useState("");
  const [metaname, setMetaname] = useState("");
  const [metacontent, setMetacontent] = useState("");

  const [publish, setPublish] = useState("");

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentaction, setAction] = useState("none");
  const [currentPerpage, setPerpage] = useState(10);
  const [currentTotal, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentSort, setSort] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [togglerefresh, setTogglerefresh] = useState(false);

  /**
   * fetchData
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchData() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/getPages"
          : "/Administrator/getPages",
        {
          params: {
            page: currentPage,
            sort: currentSort,
            rows: currentPerpage,
            search: filterText,
            deleted: userstype,
          },
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          // setLookupData(res.data.lookupdata);
          setTotal(res.data.total);
          setLoading(false);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    currentSort,
    currentPerpage,
    resetPaginationToggle,
    togglerefresh,
  ]);

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  const handlePerRowsChange = (perPage, page) => {
    setLoading(true);
    setPerpage(perPage);
    setCurrentPage(page);
  };
  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setSort([column.selector, sortDirection]);
  };

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
  );

  const handleClose = (e) => {
    //e.preventDefault();
    setShow(false);
    setPaymentShow(false);
    setErrorShow(false);
  };

  const handleUpdatePage = (paramStatus = null) => {
    //e.preventDefault();
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/savePage"
          : "/Administrator/savePage",
        {
          id: currentid ? currentid : null,
          title: title,
          route: route,
          keywords: keywords,
          topic1: topic1,
          topic2: topic2,
          topicheading1: topicheading1,
          topicheading2: topicheading2,
          metaname: metaname,
          metacontent: metacontent,
          page_status: pub_list.includes(paramStatus) ? paramStatus : publish,
          // withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShow(false);
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });
  };
  const handlePublishPage = (id = null, paramStatus = null) => {
    //e.preventDefault();
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/publishPage"
          : "/Administrator/publishPage",
        {
          id: id > 0 ? id : currentid,
          page_status: pub_list.includes(paramStatus) ? paramStatus : publish,
          // withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShow(false);
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });
  };
  function clearPage() {
    setID(0);
    setTitle("");
    setRoute("");
    setKeywords("");
    setPublish("");
    setMetacontent("");
    setMetaname("");
    setTopic1("");
    setTopic2("");
    setTopicHeading1("");
    setTopicHeading2("");
    setMeta("");
    return true;
  }
  const handleShow = (e) => {
    e.preventDefault();
    switch (e.target.getAttribute("data-action")) {
      case "add-page":
        setAction(e.target.getAttribute("data-action"));
        setID(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        clearPage();
        setShow(true);
        break;
      case "publish":
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setID(e.target.getAttribute("data-value"));
        setPublish(e.target.getAttribute("publish-action"));
        handlePublishPage(
          e.target.getAttribute("data-value"),
          e.target.getAttribute("publish-action")
        );
        break;

      case "edit":
        setAction(e.target.getAttribute("data-action"));
        setID(e.target.getAttribute("data-value"));
        console.log(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        console.log(getdata);
        setTitle(getdata.title);
        setRoute(getdata.route);
        setKeywords(getdata.keywords);
        setPublish(getdata.page_status);
        t = "";
        if (getdata.topics) {
          t = JSON.parse(getdata.topics);
          t.map((item, i) => {
            switch (i) {
              case 0:
                setTopicHeading1(item.header);
                setTopic1(item.description);
                break;
              case 1:
                setTopicHeading2(item.header);
                setTopic2(item.description);
                break;
            }
          });
        }
        let t = "";
        if (getdata.meta) {
          t = JSON.parse(getdata.meta);
          t.map((item, i) => {
            switch (i) {
              case 0:
                setMetaname(item.name);
                setMetacontent(item.content);
                break;
            }
          });
        }
        // setLastname(getdata.lastname);
        // setEmail(getdata.email);
        setShow(true);
        break;

      case "delete":
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDeleteData({
          id: e.target.getAttribute("data-value"),
          description: getdata.title,
          info:
            e.target.getAttribute("delete-action") == "1"
              ? "Delete"
              : "Activate",
        });
        setDeleteAction(e.target.getAttribute("delete-action"));
        setDeleteShow(true);
        // setTogglerefresh(!togglerefresh);
        break;
    }
  };

  const columns = [
    {
      name: "Page Heading",
      selector: "title",
      sortable: true,
    },
    {
      name: "Route",
      selector: "route",
      sortable: true,
    },
    {
      name: "Page Status",
      selector: "page_status",
      sortable: true,
    },
    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fa fa-cog"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#/edit"
              className="edt-page"
              onClick={handleShow}
              data-value={row.id}
              data-action="edit"
            >
              <i className="fa fa-pen"></i> Edit
            </Dropdown.Item>
            <Dropdown.Item
              href="#/delete"
              onClick={handleShow}
              data-value={row.id}
              data-action="delete"
              delete-action={row.deleted == 1 ? "0" : "1"}
            >
              <i className="fa fa-trash"></i>{" "}
              {row.deleted == 1 ? "Activate" : "Delete"}
            </Dropdown.Item>
            <Dropdown.Item
              href="#/publish"
              onClick={handleShow}
              data-value={row.id}
              data-action="publish"
              publish-action={
                row.page_status == "Unpublish" ? "Publish" : "Unpublish"
              }
            >
              <i className="fa fa-arrows"></i>{" "}
              {row.page_status == "Unpublish" ? "Publish" : "Unpublish"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleClear = () => {
    //if (filterText) {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText("");
    //fetchData();
    //}
  };

  const handlePublish = function (param) {
    if (pub_list.includes(param)) handlePageChange(param);
  };
  const handleDelete = (e) => {
    setDeleteShow(false);

    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/deletePage"
          : "/Administrator/deletePage",
        {
          id: e.target.getAttribute("data-value"),
          deleted: deleteaction,
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });
  };
  const search = () => {
    setTogglerefresh(!togglerefresh);
  };
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div>
        <Row>
          <Button type="button" className="round-left" onClick={handleClear}>
            X
          </Button>
          <input
            id="search"
            type="text"
            placeholder="Search by Name or Email"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                search();
              }
            }}
          />
          <Button className="round-right" type="button" onClick={search}>
            <i className="fa fa-search"></i>
          </Button>
          <Button
            type="button"
            onClick={(event) => {
              setAction("add-page");
              clearPage();
              setShow(true);
            }}
            className="round-all"
          >
            <i className="fa fa-plus-circle"></i>
          </Button>
        </Row>{" "}
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <DeleteMdl
        deletedata={deletedata}
        setDeleteShow={setDeleteShow}
        deleteshow={deleteshow}
        handleDelete={handleDelete}
      />
      <EditSEOMdl
        data={{
          route: route,
          title: title,
          topic1: topic1,
          topic2: topic2,
          topicheading1: topicheading1,
          topicheading2: topicheading2,
          keywords: keywords,
          publish: publish,
          // meta: meta,
          metaname: metaname,
          metacontent: metacontent,
        }}
        setTitle={setTitle}
        setRoute={setRoute}
        setKeywords={setKeywords}
        setTopicHeading1={setTopicHeading1}
        setTopicHeading2={setTopicHeading2}
        setTopic1={setTopic1}
        setTopic2={setTopic2}
        // setMeta={setMeta}
        setMetaname={setMetaname}
        setMetacontent={setMetacontent}
        setPublish={setPublish}
        handleClose={handleClose}
        handleUpdatePage={handleUpdatePage}
        handlePublish={handlePublish}
        show={show}
      />

      <Row>
        <Col>
          <Row>
            <Col md="12">
              <Breadcrumb>
                <Breadcrumb.Item href="/Admin/SEO/">
                  <i className="fa fa-home"></i> Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <i className="fa fa-users"></i> Pages
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Tabs id="tabs">
                <Tab
                  eventKey="pro"
                  title={
                    <div>
                      <i className="fa fa-users"></i> Pages
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <DataTable
                        // title="Users"
                        striped={true}
                        customStyles={customStyles}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        // expandableRows
                        highlightOnHover
                        defaultSortField="id"
                        // expandOnRowClicked
                        // expandableRowsComponent={<UserExpandedComponent />}
                        onSort={handleSort}
                        sortServer
                        pagination
                        paginationServer
                        paginationTotalRows={currentTotal}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                      />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default SEO;
