import React from "react";
import { Modal, Button } from "react-bootstrap";

const PriceList = ({ setShow, show }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Institutional Price List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Buying an institutional license is a cost-effective way of bringing
          the power of Trip to your organisation. Trip can be useful in many
          different ways, for instance:
        </p>
        <p>
          Supporting clinical care by helping clinicians to easily find robust,
          evidence-based, answers to their clinical questions. Trip is widely
          used by systematic review producers.
        </p>
        <p>
          The evidence-based content of Trip supports the writing of clinical
          guidelines.
        </p>
        <p>
          Preparing research grants is made easier with easy access to
          pre-existing research.
        </p>
        <p>
          Pricing Pricing is based on size and type of institution. While not
          ideal it acts as a starting point for negotiations and if you feel
          you’re disadvantaged by this method then please contact us to discuss
          further. NOTE: figures are in US Dollars and is the annual cost.
        </p>
        <h5>Academic Institution</h5>
        <p>
          Very small ({"<"}1,000 FTEs) – $995 Small (1-5,000) – $1,600 Medium
          (5-15,000 FTEs) – $2,300 Large (15,000+ FTEs) – $4,000
        </p>
        <h5>Hospitals/health centres</h5>
        <p>
          Very small ({"<"}50 beds) – $600 Small ({"<"}250 beds) – $1,225 Medium
          (250-1,000 beds) – $2,300 Large (1,000+ beds) – $3,300
        </p>
        <h5>Government, other public sector organisations and charities</h5>
        <p>
          Very small ({"<"}20 staff) – $390 Small ({"<"}75 staff) – $1,250
          Medium (75-250 staff) – $2,300 Large (250+ staff) – $3,350
        </p>
        <h5>Corporations</h5>
        <p>
          Small ({"<"}75 staff) – $2,000 Medium (75-500 staff) – $6,700 Large
          (500+ staff) – $13,500
        </p>
        <p>
          Institutions from resource poor settings. For institutions based in
          the two lowest World Bank income classification (Low-income economies
          and Lower-middle-income economies) Trip is free while those classed as
          Upper-middle-income economies generous discounts are available. For
          further details contact: lmic@tripdatabase.com
        </p>
        <h5>Authentication</h5>
        <p>
          Trip currently supports a number of methods including IP
          authentication. We are also using OpenAthens for subscribed
          institutions.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-primary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PriceList;
