import React from "react";

const GuidlinesGraphics = ({ score, handleGuidelines, publication_id }) => {
  return (
    <div className="trip-score">
      <span className="trip-score--title">Trip Score</span>

      <div className="trip-score--gauge">
        <span className="trip-score--label__red">Narrative based</span>
        <div
          className="trip-score-slider"
          data-trip-score={score > 100 ? 100 : score}
        ></div>
        <span className="trip-score--label__green">Evidence based</span>
      </div>

      <a
        type="button"
        className="trip-score--explainer-link"
        href="!/#"
        onClick={(e) => {
          handleGuidelines(e, publication_id);
        }}
        target="_blank"
      >
        ?
      </a>
    </div>
  );
};
export default GuidlinesGraphics;
