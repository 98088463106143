import React from "react";

export default () => (
  <svg
    version="1.1"
    viewBox="0 0 370.61 150"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="370.61" height="150" fill="#005eb8" strokeWidth="3.75"></rect>
    <g transform="scale(3.75)" fill="#fff">
      <path d="m9.66 3.86h10.75l6.59 22.33h0.1l4.52-22.33h8.12l-6.83 32.2h-10.7l-6.74-22.28h-0.09l-4.47 22.28h-8.12z"></path>
      <path d="m42.91 3.86h8.63l-2.54 12.32h10.2l2.54-12.32h8.63l-6.69 32.2h-8.68l2.86-13.79h-10.15l-2.86 13.79h-8.62z"></path>
      <path d="m93.06 11.15a16.18 16.18 0 0 0-7.06-1.48c-3.41 0-6.18 0.51-6.18 3.09 0 4.57 12.5 2.86 12.5 12.64 0 8.9-8.26 11.21-15.73 11.21a36 36 0 0 1-10-1.66l2-6.55c1.71 1.11 5.12 1.85 7.93 1.85s6.87-0.51 6.87-3.83c0-5.17-12.5-3.23-12.5-12.32 0-8.3 7.29-10.8 14.35-10.8 4 0 7.7 0.42 9.87 1.43z"></path>
    </g>
  </svg>
);
