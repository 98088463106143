import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.52 16.38"
  >
    <path
      d="M11,14.33a.69.69,0,1,1,.69.69A.69.69,0,0,1,11,14.33ZM6.84,11.57l2.9,2.16a2,2,0,0,0-.1.6,2.06,2.06,0,1,0,2.06-2,2,2,0,0,0-1.14.34L7.67,10.48A4.91,4.91,0,0,1,6.84,11.57ZM1.38,8.19a2.07,2.07,0,1,1,2.06,2.05A2.05,2.05,0,0,1,1.38,8.19ZM0,8.19A3.44,3.44,0,1,0,3.44,4.78,3.43,3.43,0,0,0,0,8.19Zm13.76,0a.69.69,0,1,1,.69.68A.69.69,0,0,1,13.76,8.23Zm.69,2a2.05,2.05,0,1,0-1.92-2.77H8.2a3.88,3.88,0,0,1,0,1.36h4.3A2.05,2.05,0,0,0,14.45,10.28ZM12.39,2.05a.69.69,0,1,1-.69-.68A.69.69,0,0,1,12.39,2.05Zm1.37,0a2.06,2.06,0,0,0-4.12,0,2,2,0,0,0,.1.6L6.84,4.81A4.91,4.91,0,0,1,7.67,5.9l2.89-2.15a2,2,0,0,0,1.14.35A2.05,2.05,0,0,0,13.76,2.05Z"
      fill="#b3b3b3"
    ></path>
  </svg>
);
