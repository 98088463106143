import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./App/context/auth";
import _ from "underscore";

function PrivateRouteAdmin({ component: Component, ...rest }) {
  var getdata = null;
  const { authTokens } = useAuth();
  if (authTokens) {
    getdata = _.findWhere(authTokens.roles, {
      role: "Admin",
    });
  }
  var isAdmin = false;
  if (getdata) {
    if (!_.isUndefined(getdata.role))
      isAdmin = getdata.role === "Admin" ? true : false;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? <Component {...props} /> : <Redirect to="/Admin/Login" />
      }
    />
  );
}

export default PrivateRouteAdmin;
