import React, { useState, useEffect } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import ErrorMdl from "../components/errorMdl";
import "../components/assets/css/Latest.css";

const axios = require("axios");
const _ = require("underscore");
const DocumentRedirect = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  /**
   * fetchData
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchData() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Search"
          : "/Search",
        {
          params: getParameters(),
        }
      )

      .then((res) => {
        if (res.status === 200) {
          if (!_.isUndefined(res.data.url)) {
            window.location.href = res.data.url;
            var redirect =
              '<p>If redirect is taking longer, click <a href="' +
              res.data.url +
              '">' +
              res.data.title +
              "</a></p>";
            setData(redirect);
          } else {
            setData("<p>Document not found!</p>");
          }
          setLoading(false);
        } else {
          // const error = new Error(res.error_message);
          // setErrors(res.error_message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    document.title = "Document : Trip Medical Database";

    fetchData();
  }, []);
  var keywords = !_.isUndefined(props.match.params)
    ? props.match.params.criteria
    : "";
  function getParameters() {
    keywords = keywords.replace("-", " ");
    var params = {
      criteria: keywords,
      search_type: "document",
      page: 1,
      sort: "y",
      document_id: keywords,
    };
    // setParams(params);
    return params;
  }

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <Row>
        <Col md="9">
          <Row>
            <Col>
              {hasError ? (
                <Alert variant="danger" dismissible>
                  <Alert.Heading>{hasError}!</Alert.Heading>
                  <p>
                    We cannot process your request due to {hasError}, please try
                    again later!
                  </p>
                </Alert>
              ) : (
                ""
              )}
              {loading && (
                <div>
                  <div className="spinner-border text-info"></div> Processing
                  request.....
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: data }}></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default DocumentRedirect;
