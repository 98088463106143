import React from "react";
import { Table } from "react-bootstrap";

const UpgradeTable = (params) => {
  return (
    <div>
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th></th>
            <th>Trip</th>
            <th>Trip PRO</th>
            <th>Trip PRO (Institutional)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Easy to use</td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Tens of thousands of systematic reviews</td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Largest collection of clinical guidelines</td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>

          <tr>
            <td>PICO search</td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>SmartSearch showing 3 related articles</td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Email alerts for new evidence of interest</td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Answer engine – instant answers </td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Large collection of grey literature </td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Easy to use search refinement tool</td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Easy selection of RCTs </td>
            <td>
              <i className="fa fa-check fa-lg text-primary"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Hundreds of thousands of systematic reviews </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Over 175,000 ongoing clinical trials </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Massive database of medical images </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Tens of thousands of clinical videos </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Link to over a million full-text articles </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Export links to reference management software</td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>No Adverts </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Advanced search </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Filter results by clinical area </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Article views, see which articles are most popular </td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>SmartSearch showing unlimited related articles</td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-success"></i>
            </td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>
              Link out to your organisation full-text subscription journals{" "}
            </td>
            <td></td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>No need to login to get Pro features</td>
            <td></td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
          <tr>
            <td>Institutional usage stats </td>
            <td></td>
            <td></td>
            <td>
              <i className="fa fa-check fa-lg text-info"></i>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
export default UpgradeTable;
