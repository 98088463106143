import React from "react";

const ProFeatures = () => {
  return (
    <div id="feature-comparison" className="pro-section pro-section--dark">
      <div className="container">
        <div className="row feature-comparison">
          <div className="col-12">
            <h2 className="pro-section-title text-center mb-5">
              What do I get with a Trip account?
            </h2>
            <div className="table-responsve">
              <table className="table">
                <thead>
                  <tr>
                    <th className="blank"></th>
                    <th className="curved-corner--top-left column-headers-background cell-bg--l-teal">
                      <div>
                        <svg
                          id="Layer_1"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 70.18 35.84"
                        >
                          <rect
                            width="70.18"
                            height="35.84"
                            rx="8"
                            fill="#11b6a8"
                          ></rect>
                          <path
                            d="M56,18.49H49.36a4.13,4.13,0,0,1,.29-1.27,3.51,3.51,0,0,1,.67-1,3.17,3.17,0,0,1,1-.71,3.35,3.35,0,0,1,1.33-.25,3.26,3.26,0,0,1,1.31.25,3.4,3.4,0,0,1,1,.7,3.26,3.26,0,0,1,.69,1A3.93,3.93,0,0,1,56,18.49ZM52.9,25.63a5.26,5.26,0,0,0,3.27-1,4.65,4.65,0,0,0,1.68-2.93H56a2.75,2.75,0,0,1-1,1.7A3.27,3.27,0,0,1,53,24a3.93,3.93,0,0,1-1.64-.31,3.26,3.26,0,0,1-1.14-.84,3.15,3.15,0,0,1-.65-1.21,4.32,4.32,0,0,1-.18-1.44H58A9.52,9.52,0,0,0,57.84,18,6.41,6.41,0,0,0,57,15.8a5.11,5.11,0,0,0-1.64-1.63,4.79,4.79,0,0,0-2.56-.63A5.29,5.29,0,0,0,50.6,14a5.11,5.11,0,0,0-1.72,1.26,5.74,5.74,0,0,0-1.13,1.9,6.94,6.94,0,0,0-.41,2.42,9,9,0,0,0,.4,2.44,5.21,5.21,0,0,0,1,1.93,4.68,4.68,0,0,0,1.71,1.25A5.92,5.92,0,0,0,52.9,25.63ZM44,18.49H37.33a4.13,4.13,0,0,1,.29-1.27,3.35,3.35,0,0,1,.68-1,3,3,0,0,1,1-.71,3.37,3.37,0,0,1,1.34-.25,3.29,3.29,0,0,1,1.31.25,3.35,3.35,0,0,1,1,.7,3.26,3.26,0,0,1,.69,1A3.93,3.93,0,0,1,44,18.49Zm-3.11,7.14a5.22,5.22,0,0,0,3.27-1,4.56,4.56,0,0,0,1.68-2.93H43.94a2.66,2.66,0,0,1-1,1.7,3.25,3.25,0,0,1-2,.56,4,4,0,0,1-1.64-.31,3.26,3.26,0,0,1-1.14-.84,3.29,3.29,0,0,1-.65-1.21,4.91,4.91,0,0,1-.18-1.44H46A9,9,0,0,0,45.81,18,6.41,6.41,0,0,0,45,15.8a5.11,5.11,0,0,0-1.64-1.63,4.79,4.79,0,0,0-2.56-.63,5.21,5.21,0,0,0-2.18.44,5.15,5.15,0,0,0-1.73,1.26,5.92,5.92,0,0,0-1.13,1.9,6.94,6.94,0,0,0-.4,2.42A9.37,9.37,0,0,0,35.71,22a5.36,5.36,0,0,0,1,1.93,4.68,4.68,0,0,0,1.71,1.25A6,6,0,0,0,40.87,25.63Zm-10.14-.25V20.23a7.89,7.89,0,0,1,.22-2,3.89,3.89,0,0,1,.72-1.47A3.07,3.07,0,0,1,33,15.86a5.62,5.62,0,0,1,1.95-.31v-2a4.25,4.25,0,0,0-2.55.62,5.12,5.12,0,0,0-1.7,2.09h-.05V13.8H28.82V25.38Zm-12.26,0V18.06h7.35V16.27H18.47V11.18h8.38V9.39H16.35v16Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </div>
                    </th>
                    <th className="column-headers-background cell-bg--l-yellow">
                      <div>
                        <svg
                          id="Layer_1"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 70.18 35.84"
                        >
                          <rect
                            width="70.18"
                            height="35.84"
                            rx="8"
                            fill="#ffe300"
                          ></rect>
                          <path d="M45.4,24.38a3.37,3.37,0,0,1-2.55-1.13,3.8,3.8,0,0,1-.77-1.37A5.66,5.66,0,0,1,41.8,20a5.7,5.7,0,0,1,.28-1.87,4,4,0,0,1,.77-1.38A3.41,3.41,0,0,1,44,15.91a3.52,3.52,0,0,1,2.8,0,3.32,3.32,0,0,1,1.15.85,4.21,4.21,0,0,1,.78,1.38,6.38,6.38,0,0,1,0,3.74A4,4,0,0,1,48,23.25a3.42,3.42,0,0,1-1.15.84A3.51,3.51,0,0,1,45.4,24.38Zm0,1.66a6.05,6.05,0,0,0,2.43-.46,5,5,0,0,0,1.76-1.28,5.34,5.34,0,0,0,1.07-1.91,8,8,0,0,0,0-4.76,5.43,5.43,0,0,0-1.07-1.93,5.32,5.32,0,0,0-1.76-1.29,6.49,6.49,0,0,0-4.85,0,5.26,5.26,0,0,0-1.77,1.29,5.59,5.59,0,0,0-1.07,1.93,8,8,0,0,0,0,4.76,5.5,5.5,0,0,0,1.07,1.91A5,5,0,0,0,43,25.58,6.11,6.11,0,0,0,45.4,26Zm-10.21-.25V20.64a7.83,7.83,0,0,1,.22-2,3.75,3.75,0,0,1,.72-1.47,3.11,3.11,0,0,1,1.3-.92,5.39,5.39,0,0,1,2-.31v-2a4.3,4.3,0,0,0-2.56.63,5.24,5.24,0,0,0-1.7,2.08h0V14.21H33.29V25.79Zm-9.74-8.33H21.28V11.59h4.17a3.9,3.9,0,0,1,2.65.75,2.77,2.77,0,0,1,.84,2.18,2.82,2.82,0,0,1-.84,2.2A3.8,3.8,0,0,1,25.45,17.46Zm-4.17,8.33V19.25h4.89A4.88,4.88,0,0,0,29.83,18a4.75,4.75,0,0,0,1.24-3.5A4.7,4.7,0,0,0,29.83,11,5,5,0,0,0,26.17,9.8h-7v16Z"></path>
                        </svg>
                      </div>
                    </th>
                    <th className="curved-corner--top-right column-headers-background cell-bg--l-yellow">
                      <div>
                        <svg
                          id="Layer_1"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 70.18 35.84"
                        >
                          <rect
                            width="70.18"
                            height="35.84"
                            rx="8"
                            fill="#ffe300"
                          ></rect>
                          <path d="M45.4,24.38a3.37,3.37,0,0,1-2.55-1.13,3.8,3.8,0,0,1-.77-1.37A5.66,5.66,0,0,1,41.8,20a5.7,5.7,0,0,1,.28-1.87,4,4,0,0,1,.77-1.38A3.41,3.41,0,0,1,44,15.91a3.52,3.52,0,0,1,2.8,0,3.32,3.32,0,0,1,1.15.85,4.21,4.21,0,0,1,.78,1.38,6.38,6.38,0,0,1,0,3.74A4,4,0,0,1,48,23.25a3.42,3.42,0,0,1-1.15.84A3.51,3.51,0,0,1,45.4,24.38Zm0,1.66a6.05,6.05,0,0,0,2.43-.46,5,5,0,0,0,1.76-1.28,5.34,5.34,0,0,0,1.07-1.91,8,8,0,0,0,0-4.76,5.43,5.43,0,0,0-1.07-1.93,5.32,5.32,0,0,0-1.76-1.29,6.49,6.49,0,0,0-4.85,0,5.26,5.26,0,0,0-1.77,1.29,5.59,5.59,0,0,0-1.07,1.93,8,8,0,0,0,0,4.76,5.5,5.5,0,0,0,1.07,1.91A5,5,0,0,0,43,25.58,6.11,6.11,0,0,0,45.4,26Zm-10.21-.25V20.64a7.83,7.83,0,0,1,.22-2,3.75,3.75,0,0,1,.72-1.47,3.11,3.11,0,0,1,1.3-.92,5.39,5.39,0,0,1,2-.31v-2a4.3,4.3,0,0,0-2.56.63,5.24,5.24,0,0,0-1.7,2.08h0V14.21H33.29V25.79Zm-9.74-8.33H21.28V11.59h4.17a3.9,3.9,0,0,1,2.65.75,2.77,2.77,0,0,1,.84,2.18,2.82,2.82,0,0,1-.84,2.2A3.8,3.8,0,0,1,25.45,17.46Zm-4.17,8.33V19.25h4.89A4.88,4.88,0,0,0,29.83,18a4.75,4.75,0,0,0,1.24-3.5A4.7,4.7,0,0,0,29.83,11,5,5,0,0,0,26.17,9.8h-7v16Z"></path>
                        </svg>
                        <span>Institutional</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="curved-corner--top-left">Easy to use</td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="s0 softmerge">
                      <div className="softmerge-inner">
                        Tens of thousands of systematic reviews
                      </div>
                    </td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Largest collection of clinical guidelines</td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>PICO search</td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="s0 softmerge">
                      SmartSearch showing 3 related articles
                    </td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="s0 softmerge">
                      Email alerts for new evidence of interest
                    </td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Answer engine – instant answers</td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Large collection of grey literature</td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Easy to use search refinement tool</td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Easy selection of RCTs</td>
                    <td className="cell-bg--l-teal">
                      <i className="fa fa-check fa-teal"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="s1 softmerge">
                      Hundreds of thousands of systematic reviews
                    </td>
                    <td className="cell-bg--dark s2"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Over 175,000 ongoing clinical trials</td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>

                  <tr>
                    <td>Link to over a million full-text articles</td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="s1">
                      Export links to reference management software
                    </td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>

                  <tr>
                    <td>Advanced search</td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Filter results by clinical area</td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="s1 softmerge">
                      Article views, see which articles are most popular
                    </td>
                    <td className="cell-bg--dark s2"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="s1 softmerge">
                      SmartSearch showing unlimited related articles
                    </td>
                    <td className="cell-bg--dark s2"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="s1 softmerge">
                      Link out to your organisation full-text subscription
                      journals
                    </td>
                    <td className="cell-bg--dark s3"></td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>No need to login to get Pro features</td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                  <tr>
                    <td className="curved-corner--bottom-left">
                      Institutional usage stats
                    </td>
                    <td className="cell-bg--dark"></td>
                    <td className="cell-bg--dark"></td>
                    <td className="curved-corner--bottom-right cell-bg--l-yellow">
                      <i className="fa fa-check fa-yellow"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProFeatures;
