import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 88.96 88.96"
  >
    <title>inline-icon-twitter</title>
    <path
      d="M78.3,29.79c0-.75,0-1.51,0-2.26A35.67,35.67,0,0,0,87,18.44,34.78,34.78,0,0,1,76.93,21.2a17.64,17.64,0,0,0,7.71-9.71A35.2,35.2,0,0,1,73.5,15.76a17.55,17.55,0,0,0-30.36,12,17.73,17.73,0,0,0,.45,4A49.92,49.92,0,0,1,7.41,13.43a17.55,17.55,0,0,0,5.44,23.43,17.54,17.54,0,0,1-8-2.2v.22A17.56,17.56,0,0,0,19,52.09a17.52,17.52,0,0,1-4.62.62,17.92,17.92,0,0,1-3.3-.32,17.57,17.57,0,0,0,16.4,12.19A35.23,35.23,0,0,1,5.64,72.1a36.26,36.26,0,0,1-4.19-.25,49.71,49.71,0,0,0,26.91,7.89C60.65,79.74,78.3,53,78.3,29.79Z"
      fill="#4a4a4a"
    ></path>
  </svg>
);
