import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 216.28 109"
  >
    <g>
      <g>
        <path
          d="M59.36,14.25V10.79q0-5.58-3.34-5.58H3.34Q0,5.21,0,10.79v3.46q0,5.57,3.34,5.57H21.05v61.9H38.31V19.82H56q3.34,0,3.34-5.57M78.13,26.38q-7.47,0-12.14,8.48a17,17,0,0,1-.39-1.79A17.79,17.79,0,0,0,65.1,31c-.15-.41-.38-.93-.67-1.56a3.23,3.23,0,0,0-1.17-1.4,6.56,6.56,0,0,0-2.95-.44,38.78,38.78,0,0,0-6.74.89q-4.51.9-4.51,2.68a32.78,32.78,0,0,0,.67,4A69.65,69.65,0,0,1,50.4,46.9V81.72H66.88V46.45c2.23-3.71,5.19-5.57,8.91-5.57a11.35,11.35,0,0,1,2.89.45,11.28,11.28,0,0,0,2.34.44c1,0,1.79-1.17,2.45-3.51a22.09,22.09,0,0,0,1-5.86,6.75,6.75,0,0,0-1.4-4.18c-.93-1.22-2.58-1.84-5-1.84m81.25,8q-5.79-8-15-8a17.23,17.23,0,0,0-9,2.34,15.67,15.67,0,0,0-6,6,16.76,16.76,0,0,1-.39-1.68,19.62,19.62,0,0,0-.5-2,14.55,14.55,0,0,0-.67-1.62,3.23,3.23,0,0,0-1.17-1.4,6.53,6.53,0,0,0-3-.44,38.78,38.78,0,0,0-6.74.89q-4.52.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71v57.18h16.48V83a18.85,18.85,0,0,0,11.36,3.46q11.13,0,17.32-8.09t6.18-22.08q0-14-5.79-22M138.11,73.44a15.68,15.68,0,0,1-7.8-2.12V46.45q3.78-6.57,9.13-6.57,9,0,9,16.78T138.11,73.44m-39-68.85H96c-4.46,0-6.68,1.19-6.68,3.57v8.11h16.48V8.16q0-3.57-6.68-3.57m0,22.85H96c-4.46,0-6.68,1.19-6.68,3.57V81.72h16.48V31q0-3.57-6.68-3.57"
          fill="#533764"
          fillRule="evenodd"
        ></path>
        <path
          d="M37.33,82.12v.58q0,3.9-6.36,3.9H27.26q-6.36,0-6.36-3.9v-.58Z"
          fill="#63c608"
          fillRule="evenodd"
        ></path>
        <path
          d="M67.19,82.12v.58q0,3.9-6.66,3.9h-3.1q-6.66,0-6.66-3.9v-.58Z"
          fill="#0e6cbb"
          fillRule="evenodd"
        ></path>
        <path
          d="M106,82.12V83c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
          fill="#00a89d"
          fillRule="evenodd"
        ></path>
        <path
          d="M130.7,104.65v.9q0,3.45-6.55,3.45h-3.06q-6.56,0-6.55-3.45v-.9Z"
          fill="#eec82f"
          fillRule="evenodd"
        ></path>
        <path
          d="M106,16.42v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
          fill="#ba390d"
          fillRule="evenodd"
        ></path>
      </g>
      <rect x="146.1" width="70.18" height="35.84" rx="8" fill="#11b6a8"></rect>
      <path
        d="M202.11,18.49h-6.65a4.13,4.13,0,0,1,.29-1.27,3.51,3.51,0,0,1,.67-1,3.17,3.17,0,0,1,1-.71,3.37,3.37,0,0,1,1.34-.25,3.32,3.32,0,0,1,1.31.25,3.48,3.48,0,0,1,1,.7,3.26,3.26,0,0,1,.69,1A3.93,3.93,0,0,1,202.11,18.49ZM199,25.63a5.26,5.26,0,0,0,3.27-1A4.56,4.56,0,0,0,204,21.71h-1.88a2.75,2.75,0,0,1-1,1.7,3.27,3.27,0,0,1-2,.56,3.93,3.93,0,0,1-1.64-.31,3.26,3.26,0,0,1-1.14-.84,3.15,3.15,0,0,1-.65-1.21,4.91,4.91,0,0,1-.18-1.44h8.67a9.52,9.52,0,0,0-.19-2.22,6.41,6.41,0,0,0-.85-2.15,5.11,5.11,0,0,0-1.64-1.63,4.79,4.79,0,0,0-2.56-.63,5.29,5.29,0,0,0-2.19.44A5.11,5.11,0,0,0,195,15.24a5.74,5.74,0,0,0-1.13,1.9,6.94,6.94,0,0,0-.41,2.42,9.36,9.36,0,0,0,.4,2.44,5.36,5.36,0,0,0,1,1.93,4.68,4.68,0,0,0,1.71,1.25A5.92,5.92,0,0,0,199,25.63Zm-8.91-7.14h-6.66a4.13,4.13,0,0,1,.29-1.27,3.35,3.35,0,0,1,.68-1,3,3,0,0,1,1-.71,3.61,3.61,0,0,1,2.64,0,3.35,3.35,0,0,1,1,.7,3.26,3.26,0,0,1,.69,1A3.94,3.94,0,0,1,190.09,18.49ZM187,25.63a5.22,5.22,0,0,0,3.27-1,4.56,4.56,0,0,0,1.68-2.93H190a2.66,2.66,0,0,1-1,1.7,3.23,3.23,0,0,1-2,.56,4,4,0,0,1-1.64-.31,3.26,3.26,0,0,1-1.14-.84,3.29,3.29,0,0,1-.65-1.21,4.91,4.91,0,0,1-.18-1.44h8.67a9,9,0,0,0-.19-2.22,6.41,6.41,0,0,0-.85-2.15,5.11,5.11,0,0,0-1.64-1.63,4.76,4.76,0,0,0-2.56-.63,5.21,5.21,0,0,0-2.18.44A5.15,5.15,0,0,0,183,15.24a5.92,5.92,0,0,0-1.13,1.9,6.94,6.94,0,0,0-.4,2.42,9.37,9.37,0,0,0,.39,2.44,5.36,5.36,0,0,0,1,1.93,4.68,4.68,0,0,0,1.71,1.25A6,6,0,0,0,187,25.63Zm-10.14-.25V20.23a7.89,7.89,0,0,1,.22-2,3.89,3.89,0,0,1,.72-1.47,3.11,3.11,0,0,1,1.3-.92,5.52,5.52,0,0,1,1.94-.31v-2a4.25,4.25,0,0,0-2.55.62,5.12,5.12,0,0,0-1.7,2.09h0V13.8h-1.79V25.38Zm-12.26,0V18.06h7.35V16.27h-7.35V11.18H173V9.39h-10.5v16Z"
        fill="#fff"
      ></path>
    </g>
  </svg>
);
