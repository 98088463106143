import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.32 16.07"
  >
    <polygon
      points="0.5 0.5 0.5 15.05 4.66 11.86 8.82 15.05 8.82 0.5 0.5 0.5"
      fill="none"
      stroke="#b3b3b3"
      strokeMiterlimit="10"
    ></polygon>
  </svg>
);
