import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
const EditSEOMdl = ({
  data,
  setTitle,
  setRoute,
  setKeywords,
  setTopic1,
  setTopic2,
  setTopicHeading1,
  setTopicHeading2,
  //   setMeta,
  setMetaname,
  setMetacontent,
  //   setPublish,
  handleClose,
  handleUpdatePage,
  handlePublish,
  show,
}) => {
  // return results
  var current_selection = "Quality";

  return (
    <Modal show={show} onHide={handleClose} size="xl" animation={false}>
      <Form width="100%">
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Page Heading</Form.Label>
            <Form.Control
              type="text"
              name="title"
              placeholder="Enter page heading 1"
              value={data.title ? data.title : ""}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2">
            <Form.Label>Route</Form.Label>
            <Form.Control
              type="text"
              name="route"
              placeholder="route name without /,?,"
              value={data.route ? data.route : ""}
              onChange={(e) => setRoute(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="topicheading1">
            <Form.Label>Topic 1 Heading</Form.Label>
            <Form.Control
              type="text"
              name="topicheading1"
              placeholder="Heading"
              value={data.topicheading1 ? data.topicheading1 : ""}
              onChange={(e) => setTopicHeading1(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput3">
            <Form.Label>Topic 1 Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Topic Description"
              name="topic1"
              value={data.topic1 ? data.topic1 : ""}
              onChange={(e) => setTopic1(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="topic2heading">
            <Form.Label>Topic 2 Heading</Form.Label>
            <Form.Control
              type="text"
              name="topicheading2"
              placeholder="Topic 2 heading"
              value={data.topicheading2 ? data.topicheading2 : ""}
              onChange={(e) => setTopicHeading2(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="topi2">
            <Form.Label>Topic 2 Description</Form.Label>
            <Form.Control
              name="topic2"
              as="textarea"
              placeholder="Topic 2 description"
              rows={3}
              value={data.topic2 ? data.topic2 : ""}
              onChange={(e) => setTopic2(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="keywords">
            <Form.Label>Keywords (separate by ,)</Form.Label>
            <Form.Control
              as="textarea"
              name="keywords"
              rows={3}
              placeholder="separate keywords by comma(,)"
              value={data.keywords ? data.keywords : ""}
              onChange={(e) => setKeywords(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="metaid">
            <Form.Label>Page Title</Form.Label>
            <Form.Control
              name="metaname"
              type="text"
              placeholder="enter meta name"
              value={data.metaname ? data.metaname : ""}
              onChange={(e) => setMetaname(e.target.value)}
            />
            <Form.Label>Meta Content (Description)</Form.Label>
            <Form.Control
              name="metacontent"
              type="text"
              placeholder="enter meta content"
              value={data.metacontent ? data.metacontent : ""}
              onChange={(e) => setMetacontent(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button
            variant="warning"
            onClick={async (e) => {
              handlePublish(
                e,
                data.publish === "Unpublished" ? "Published" : "Unpublished"
              );
            }}
          >
            {data.publish === "Unpublished" ? " Publish " : " Unpublish"}
          </Button> */}
          <Button variant="primary" onClick={handleUpdatePage}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditSEOMdl;
