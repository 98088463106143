import React, { useState, useEffect, useMemo } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Dropdown,
  Table,
  Breadcrumb,
  Form,
  Button,
  Card,
} from "react-bootstrap";
//import styled from "styled-components";
import DataTable from "react-data-table-component";
import SidebarAdmin from "../../components/SidebarAdmin";
import ErrorMdl from "../../components/errorMdl";
import DeleteMdl from "../../components/deleteMdl";

import "../../components/assets/css/Tabs.css";
//import { orderBy } from "lodash";
import EditUserMdl from "./EditUserMdl";
import PaymentMdl from "./PaymentMdl";
// const dateFormat = require("dateformat");
const axios = require("axios");
const _ = require("underscore");
const { customStyles } = require("./tableStyle");

const Users = (props) => {
  //  Modal.setAppElement(".edt-modal");

  const [show, setShow] = useState(false);
  const [paymentshow, setPaymentShow] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [deleteshow, setDeleteShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [lookupdata, setLookupData] = useState([]);
  const [deletedata, setDeleteData] = useState([]);
  const [deleteaction, setDeleteAction] = useState(1);

  const [userstype, setUsersType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentid, setID] = useState(0);
  var futuredate = new Date();
  futuredate.setFullYear(futuredate.getFullYear() + 1);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [paid, setPaid] = useState("");
  const [notes, setNotes] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(futuredate);

  const [payment, setPayment] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentaction, setAction] = useState("none");
  const [currentPerpage, setPerpage] = useState(10);
  const [currentTotal, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentSort, setSort] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [togglerefresh, setTogglerefresh] = useState(false);

  /**
   * fetchData
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchData() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/getData"
          : "/Administrator/getData",
        {
          params: {
            page: currentPage,
            sort: currentSort,
            rows: currentPerpage,
            search: filterText,
            deleted: userstype,
          },
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setLookupData(res.data.lookupdata);
          setTotal(res.data.total);
          setLoading(false);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    currentSort,
    currentPerpage,
    resetPaginationToggle,
    userstype,
    togglerefresh,
  ]);
  const handleUserstype = (e) => {
    setLoading(true);

    setUsersType(e.target.value);
    setTogglerefresh(!togglerefresh);
  };
  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  const handlePerRowsChange = (perPage, page) => {
    setLoading(true);
    setPerpage(perPage);
    setCurrentPage(page);
  };
  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setSort([column.selector, sortDirection]);
  };

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
  );

  const handleClose = (e) => {
    //e.preventDefault();
    setShow(false);
    setPaymentShow(false);
    setErrorShow(false);
  };

  const handleUpdateUser = (e) => {
    //e.preventDefault();
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/updateUser"
          : "/Administrator/updateUser",
        {
          user_id: currentid,
          firstname: firstname,
          lastname: lastname,
          email: email,
          withCredentials: true,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setShow(false);
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });
  };

  const handleShow = (e) => {
    e.preventDefault();
    switch (e.target.getAttribute("data-action")) {
      case "edit":
        setAction(e.target.getAttribute("data-action"));
        setID(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setFirstname(getdata.firstname);
        setLastname(getdata.lastname);
        setEmail(getdata.email);
        setShow(true);
        break;
      case "payment":
        setAction(e.target.getAttribute("data-action"));
        setID(e.target.getAttribute("data-value"));

        //fetchPayment(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setFirstname(getdata.firstname);
        setLastname(getdata.lastname);
        setEmail(getdata.email);
        setPaymentShow(true);
        break;
      case "delete":
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDeleteData({
          id: e.target.getAttribute("data-value"),
          description: getdata.firstname + " " + getdata.lastname,
          info:
            e.target.getAttribute("delete-action") == "1"
              ? "Delete"
              : "Activate",
        });
        setDeleteAction(e.target.getAttribute("delete-action"));
        setDeleteShow(true);
        // setTogglerefresh(!togglerefresh);
        break;
    }
  };

  const columns = [
    {
      name: "First Name",
      selector: "firstname",
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "lastname",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Country",
      selector: "country_name",
      sortable: false,
    },
    {
      name: "Profession",
      selector: "title",
      sortable: false,
      right: true,
    },
    {
      cell: (row) => (
        <Dropdown>
          {/* <Dropdown style={{ position: "relative", zIndex: 1000 }}> */}
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fa fa-cog"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#/edit"
              className="edt-user"
              onClick={handleShow}
              data-value={row.id}
              data-action="edit"
            >
              <i className="fa fa-pencil"></i> Edit
            </Dropdown.Item>
            <Dropdown.Item
              href="#/delete"
              onClick={handleShow}
              data-value={row.id}
              data-action="delete"
              delete-action={row.deleted == 1 ? "0" : "1"}
            >
              <i className="fa fa-trash"></i>{" "}
              {row.deleted == 1 ? "Activate" : "Delete"}
            </Dropdown.Item>
            <Dropdown.Item
              href="#/payment"
              onClick={handleShow}
              data-value={row.id}
              data-action="payment"
            >
              <i className="fa fa-user-o"></i> Add Payment
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // eslint-disable-next-line
  const UserExpandedComponent = ({ data }) => {
    var clinical_areas = [];
    clinical_areas = !_.isUndefined(lookupdata.clinical_areas)
      ? lookupdata.clinical_areas
      : [];
    var areas = "";
    if (!_.isEmpty(data.clinicalareaids)) {
      ///areas = searcharea(data, clinical_areas);
      var getclinicalarea = !_.isEmpty(data.clinicalareaids)
        ? data.clinicalareaids.split(",")
        : [];
      var areas = _.chain(getclinicalarea)
        .map(function (val) {
          var selected_areas = "";
          var getArea = _.findWhere(clinical_areas, {
            value: parseInt(val, 10),
          });
          if (getArea) selected_areas += getArea.label ? getArea.label : "";
          return selected_areas;
        })
        .join(" , ")
        .value();
    }
    return (
      <Card variant="info" border="success">
        <Card.Body>
          <Card.Header className="text text-success">
            <h4>
              {data.firstname} {data.lastname}
            </h4>
          </Card.Header>
          <Row className="mt-2">
            <Col md="2">Interests</Col> <Col>{data.interests}</Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">Clinical Area</Col>
            <Col>{areas}</Col>
          </Row>
          {!_.isEmpty(data.payments) ? (
            <Row className="mt-2">
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>Reminder Date</th>
                      <th>Amount</th>
                      <th>Paid</th>
                      <th>Active</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.payments.map((transaction, idx) => (
                      <tr key={idx}>
                        <td>{transaction.date_from}</td>
                        <td>{transaction.date_to}</td>
                        <td>{transaction.date_reminded}</td>
                        <td>{transaction.amount}</td>
                        <td>{transaction.paid}</td>
                        <td>
                          {transaction.active === 1 ? (
                            <i className="fa fa-check-circle text-success"></i>
                          ) : (
                            <i className="fa fa-times-circle text-danger"></i>
                          )}
                        </td>
                        <td>{transaction.notes}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            <h5 className="text-warning">No Payment Added!</h5>
          )}
        </Card.Body>
      </Card>
    );
  };

  const handleClear = () => {
    //if (filterText) {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText("");
    //fetchData();
    //}
  };

  const handlePayment = (event) => {
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/savePayment"
          : "/Administrator/savePayment",
        {
          user_id: currentid,
          date_from: startDate,
          date_to: endDate,
          amount: amount,
          paid: paid,
          notes: notes,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setPaymentShow(false);
          setLoading(true);
          setTogglerefresh(!togglerefresh);

          // fetchData();
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });

    event.preventDefault();
  };
  const handleDelete = (e) => {
    setDeleteShow(false);

    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Administrator/deleteUser"
          : "/Administrator/deleteUser",
        {
          user_id: e.target.getAttribute("data-value"),
          deleted: deleteaction,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });
  };
  const search = () => {
    setTogglerefresh(!togglerefresh);
  };
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div>
        <Row>
          <Col>
            <Form>
              <Form.Control
                width="100%"
                as="select"
                defaultValue={userstype}
                onChange={handleUserstype}
              >
                <option value="0">Active Users</option>
                <option value="1">Archived Users</option>
              </Form.Control>
            </Form>
          </Col>
        </Row>
        <Row>
          <Button type="button" className="round-left" onClick={handleClear}>
            X
          </Button>
          <input
            id="search"
            type="text"
            placeholder="Search by Name or Email"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                search();
              }
            }}
          />
          <Button className="round-right" type="button" onClick={search}>
            <i className="fa fa-search"></i>
          </Button>
        </Row>{" "}
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <DeleteMdl
        deletedata={deletedata}
        setDeleteShow={setDeleteShow}
        deleteshow={deleteshow}
        handleDelete={handleDelete}
      />
      <EditUserMdl
        user={{ firstname: firstname, lastname: lastname, email: email }}
        setEmail={setEmail}
        setFirstname={setFirstname}
        setLastname={setLastname}
        handleClose={handleClose}
        handleUpdateUser={handleUpdateUser}
        show={show}
      />
      <PaymentMdl
        user={{
          firstname: firstname,
          lastname: lastname,
          email: email,
          amount: amount,
          paid: paid,
          notes: notes,
          institution: false,
        }}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setAmount={setAmount}
        setPaid={setPaid}
        setNotes={setNotes}
        handleClose={handleClose}
        handlePayment={handlePayment}
        show={paymentshow}
      />

      <Row>
        <SidebarAdmin></SidebarAdmin>

        <Col>
          <Row>
            <Col md="12">
              <Breadcrumb>
                <Breadcrumb.Item href="/Admin">
                  <i className="fa fa-home"></i> Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <i className="fa fa-users"></i> Users
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Tabs id="tabs">
                <Tab
                  eventKey="pro"
                  title={
                    <div>
                      <i className="fa fa-users"></i> Users
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <DataTable
                        // title="Users"
                        striped={true}
                        customStyles={customStyles}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        expandableRows
                        highlightOnHover
                        defaultSortField="name"
                        expandOnRowClicked
                        expandableRowsComponent={<UserExpandedComponent />}
                        onSort={handleSort}
                        sortServer
                        pagination
                        paginationServer
                        paginationTotalRows={currentTotal}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                      />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Users;
