import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 156.86 104.44"
  >
    <g>
      <path
        d="M23.43,0A23.42,23.42,0,0,0,0,23.4V46.8A23.41,23.41,0,0,0,23.43,70.2H99.6A23.41,23.41,0,0,0,123,46.8V23.4A23.42,23.42,0,0,0,99.6,0Z"
        fill="#ffe300"
        fillRule="evenodd"
      ></path>
      <g>
        <path
          d="M40,14.19q5.84,0,8.84,3t3,8.42q0,5.41-3,8.44c-2,2-5,3-8.84,3H28.18V52.84H23.05V14.19ZM38.24,32.7q4.38.06,6.41-1.79a6.78,6.78,0,0,0,2-5.3,6.73,6.73,0,0,0-2-5.28q-2-1.81-6.41-1.81H28.18V32.7Z"
          fillRule="evenodd"
        ></path>
        <path
          d="M61.49,24.85v5.9h.11a12.33,12.33,0,0,1,4.11-5,10.26,10.26,0,0,1,6.16-1.52v4.87a13,13,0,0,0-4.7.76A7.48,7.48,0,0,0,64,32.05,9.21,9.21,0,0,0,62.3,35.6a19.14,19.14,0,0,0-.54,4.79V52.84h-4.6v-28Z"
          fillRule="evenodd"
        ></path>
        <path
          d="M73.71,33.11a13,13,0,0,1,2.59-4.66,12.3,12.3,0,0,1,4.27-3.11,15.61,15.61,0,0,1,11.71,0,12.19,12.19,0,0,1,4.24,3.11,13,13,0,0,1,2.6,4.66,19.65,19.65,0,0,1,0,11.5,13.08,13.08,0,0,1-2.6,4.63,11.75,11.75,0,0,1-4.24,3.08,16,16,0,0,1-11.71,0,11.86,11.86,0,0,1-4.27-3.08,13.06,13.06,0,0,1-2.59-4.63,19.44,19.44,0,0,1,0-11.5m4.67,10.28a9.59,9.59,0,0,0,1.87,3.3,7.89,7.89,0,0,0,2.78,2,8.4,8.4,0,0,0,6.76,0,7.93,7.93,0,0,0,2.79-2,9.42,9.42,0,0,0,1.86-3.3,14,14,0,0,0,.68-4.52,14,14,0,0,0-.68-4.52A9.75,9.75,0,0,0,92.58,31,8,8,0,0,0,89.79,29,8.51,8.51,0,0,0,83,29,8,8,0,0,0,80.25,31a9.93,9.93,0,0,0-1.87,3.33,14,14,0,0,0-.67,4.52,14,14,0,0,0,.67,4.52"
          fillRule="evenodd"
        ></path>
      </g>
      <path
        d="M98.08,68.36A22.71,22.71,0,0,0,103,82.44a31,31,0,0,0,30-30.95V49.05A22.79,22.79,0,0,0,98.08,68.36Z"
        fill="#ffe300"
        fillRule="evenodd"
      ></path>
      <path d="M138.16,84.07a23.55,23.55,0,0,0,6.13-15.87,23.83,23.83,0,1,0-7.91,17.64l18.7,18.6,1.78-1.77Zm-17.72,5.65a21.5,21.5,0,1,1,21.62-21.5A21.57,21.57,0,0,1,120.44,89.72Z"></path>
    </g>
  </svg>
);
