import React from "react";

const Key = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1>New Trip Explained</h1>

          <p>The new trip icons and images are explained below.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_1.png")}
            alt="Pro only features"
          />
          <h4>Pro only Features</h4>
          <p>
            Yellow ‘Pro’ flashes highlight extra content or features you obtain
            with a Pro subscription
          </p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_2.png")}
            alt="Ordering Results"
          />
          <h4>Ordering Results</h4>
          <p>Alters how Trip orders the results</p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_3.png")}
            alt="Document Snippets"
          />
          <h4>Document Snippets</h4>
          <p>Turn on or off the document snippets </p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_4.png")}
            alt="Filtering Search Results"
          />
          <h4>Filtering Search Results</h4>
          <p>Filter your search by evidence type, clinical area etc.</p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_5.png")}
            alt="Article Evidence Type"
          />
          <h4>Article Evidence Type</h4>
          <p>
            Highlights the ‘evidence type’ category for the article. this links
            to filter on the left-hand side
          </p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_pyramids_key.png")}
            alt="Pyramids"
          />
          <h4>Pyramids</h4>
          <p>
            A simply tool to help convey the potential robustness of the
            evidence (for more information{" "}
            <a href="https://blog.tripdatabase.com/2021/09/26/pyramids-are-back/">
              click here
            </a>
            )
          </p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_6.png")}
            alt="Dead links"
          />
          <h4>Dead links</h4>
          <p>
            If you come across a broken link please use this and Trip will try
            to fix it
          </p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_7.png")}
            alt="Export Results"
          />
          <h4>Export Results</h4>
          <p>Export articles in a variety of formats </p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_8.png")}
            alt="Article Format / Length"
          />
          <h4>Article Format / Length</h4>
          <p>Indicates if the article is available as full-text or abstract</p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_9.png")}
            alt="Sharing on Twitter"
          />
          <h4>Sharing on Twitter</h4>
          <p>Tweet the article from within Trip</p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_10.png")}
            alt="Bookmarks"
          />
          <h4>Bookmarks</h4>
          <p>Bookmark the article so you can view it later</p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_11.png")}
            alt="Related articles"
          />
          <h4>Related articles</h4>
          <p>Related articles</p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_12.png")}
            alt="Risk of Bias"
          />
          <h4>Risk of Bias</h4>
          <p>
            For Randomised Controlled Trials, using RobotReviewer, we estimate
            the Risk of Bias for the trial
          </p>
          <hr></hr>
        </div>

        <div className="col-12 key-point">
          <img
            className="key-image border border-info"
            src={require("../components/assets/keys/trip_key_13.png")}
            alt="Your Profile - Pro only Feature"
          />
          <h4>Your Profile (Pro Feature)</h4>
          <p>
            Links to your profile, including bookmarks, search history, inbox
            and password reset
          </p>
          <hr></hr>
        </div>
      </div>
    </div>
  );
};

export default Key;
