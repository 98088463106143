import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import ErrorMdl from "../components/errorMdl";
import { getQueryStringValue } from "../components/search/queryString";
import { set } from "store";
const axios = require("axios");

const Unsubscribe = (props) => {
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [showPasswordtxt, setPasswordtxt] = useState("");

  async function fetchCheck() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/unsubscribe"
          : "/User/unsubscribe",
        {
          params: { key: getQueryStringValue("_t") },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setPasswordtxt(res.data.message);
          } else {
            setErrors({ message: res.data.message });
            setErrorShow(true);
          }
        } else {
          const error = new Error(res.message);
          setErrors({ message: res.message });
          setErrorShow(true);
        }
      })
      .catch((error) => {
        if (!_.isEmpty(error)) {
          setErrors(error);
          setErrorShow(true);
        }
      });
  }

  useEffect(() => {
    fetchCheck();
  }, []);

  return (
    <main role="main" className="main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <Row></Row>
      <Row>
        <Col md="3"></Col>
        <Col md="9">
          <Row>
            <Col>
              {showPasswordtxt && (
                <Card variant="info">
                  <Card.Body>
                    <Card.Header>
                      <h2>Unsubscribe!</h2>
                    </Card.Header>
                    <p>{showPasswordtxt}</p>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Unsubscribe;
