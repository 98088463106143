import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import _ from "underscore";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
// import axios from "axios";
import PublicationPromiseOptions from "./PublicationPromiseOptions";
const ArticleMdl = ({
  article,
  publicationdata,
  setArticlePub,
  setArticlePubcurrent,
  setArticleTitle,
  setArticleURL,
  setArticleDate,
  setArticleIndexed,
  handleClose,
  handleArticle,
  handleArticleAction,
  show,
}) => {
  const handlePubSelect = (newValue) => {
    setArticlePubcurrent(newValue);
    if (newValue.value) {
      setArticlePub(newValue.value);
    }
  };
  return (
    <Modal show={show} onHide={handleClose} animation={false} size="xl">
      <Form width="100%" onSubmit={handleArticle}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i>{" "}
            {article.currentaction && article.currentaction == "add-article"
              ? "Add Article"
              : "Update Article -" + article.article_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="article.title">
            <Form.Label>
              <i className="fa fa-info-circle"></i> Title
            </Form.Label>
            <Form.Control
              type="text"
              name="article"
              placeholder="Title"
              value={article.title ? article.title : ""}
              onChange={(e) => setArticleTitle(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="paymentForm.to">
            <Form.Label>
              <i className="fa fa-calendar"></i> URL
            </Form.Label>
            <Form.Control
              type="text"
              name="articleurl"
              placeholder="URL"
              value={article.url ? article.url : ""}
              onChange={(e) => setArticleURL(e.target.value)}
            />
            {article.url && (
              <a href={article.url} target="_blank">
                Check
              </a>
            )}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Publication</Form.Label>

            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={PublicationPromiseOptions}
              value={article.articlepubcurrent ? article.articlepubcurrent : ""}
              onChange={(selectedOptions) => handlePubSelect(selectedOptions)}
            />
          </Form.Group>

          <Form.Group controlId="article.date">
            <Form.Label>
              <i className="fa fa-calendar"></i> Date
            </Form.Label>
            <Form.Control
              type="text"
              name="weight"
              value={article.trip_date ? article.trip_date : ""}
              onChange={(e) => setArticleDate(e.target.value)}
            />
          </Form.Group>

          {article.currentaction && article.currentaction == "edit-article" ? (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Status</Form.Label>
              <Form.Control
                width="100%"
                as="select"
                defaultValue={article.indexed}
                onChange={(e) => setArticleIndexed(e.target.value)}
              >
                <option value="0">Indexed</option>
                <option value="1">Needs Indexing</option>
                <option value="-1">Removed Already</option>
                <option value="-11">Remove/Index</option>
                <option value="4">Update/Re-index</option>
              </Form.Control>
            </Form.Group>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            data-value={article.article_id}
            data-title={article.title ? article.title : ""}
            data-action="delete-article"
            onClick={handleArticleAction}
          >
            <i className="fa fa-trash"></i> Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button variant="primary" onClick={handleArticle}>
            <i className="fa fa-disc"></i> Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ArticleMdl;
