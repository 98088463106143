import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import Sidebar from "../components/SidebarRegistration";
// import LoginForm from "../components/LoginForm";
import { useAuth } from "./../context/auth";
import ErrorMdl from "../components/errorMdl";
// import PageContent from "../components/PageContent";
import { remember, checkRemember } from "../context/rememberMe";
import { wayfless } from "../context/wayfless";
import { checkTokensStorage } from "../context/checkTokens";

const axios = require("axios");
const pk = "9lsoo3-4924-4kkkka-llikh2";
const store = require("store");
const jwt = require("jsonwebtoken");

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberme, setRememberme] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);

  const { setAuthTokens } = useAuth();
  // const [data, setData] = useState([]);
  var loginObj = checkTokensStorage();

  let wf = document.getElementById("wayfinderEmbedded");

  if (wf) window.location.reload();
  /**
   * on page load/update
   */
  let oa_login = false;
  if (!_.isUndefined(loginObj.chkInstitution)) {
    oa_login = !_.isUndefined(loginObj.chkInstitution.oa_login)
      ? loginObj.chkInstitution.oa_login
      : oa_login;
  }
  useEffect(() => {
    //
    document.title = "Login - Trip Medical Database";
    document.body.classList.remove("homepage");
    checkRemember(setUsername, setPassword, setRememberme);
    wayfless(
      window,
      document,
      "https://wayfinder.openathens.net/embed/",
      "/loader.js",
      // "7ca0328c-55c6-4bfe-b851-0e1b82b9bd5b"
      "c405b315-2492-403b-a8af-d279e7d52798"
    );
  }, []);
  return (
    <main role="main" className="main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <Row></Row>
      <Row>
        <Col md="3">
          <Sidebar {...props} />
        </Col>
        <Col md="9">
          <Row>
            <Col md="9">
              <div className="card">
                <div className="card-body">
                  <div className="card-header">
                    <h2>
                      Institution Login{" "}
                      <img
                        style={{
                          overflow: "hidden",
                          maxHeight: "70px",
                          maxWidth: "120px",
                          align: "right",
                        }}
                        src={require("../components/assets/openathens.png")}
                      ></img>
                    </h2>
                  </div>
                  <br />
                  <div className="form-group row">
                    <div className="col-9">
                      {/* <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className="wayfinder-login btn btn-primary btn-lg pull-right btn btn-primary"
                      >
                        <i className="fa fa-university"></i> Institution login
                      </a> */}
                      <div id="wayfinder">Loading...</div>
                    </div>
                  </div>{" "}
                  {loginObj.chkInstitution.institution_name && (
                    <div>
                      {" "}
                      <div className="mb-4 alert alert-secondary" role="alert">
                        {loginObj.chkInstitution.institution_name}{" "}
                        <a className="alert-link" href="/Logout">
                          Logout?
                        </a>
                      </div>
                    </div>
                  )}
                  {oa_login ? (
                    <div>
                      <div className="mb-4 alert alert-primary" role="alert">
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          className="pro-tooltip"
                          overlay={
                            <Tooltip id={"overlay-openathens-logut"}>
                              OpenAthens caches your previous login details.
                              Click here, if you would like to change or fully
                              logout from your current institution{" "}
                            </Tooltip>
                          }
                          // delay={{ show: 40, hide: 1800 }}
                        >
                          <a
                            href="https://login.openathens.net/signout"
                            target="_blank"
                            rel="nofollow"
                          >
                            {" "}
                            Logout{" "}
                            <img
                              style={{
                                overflow: "hidden",
                                maxHeight: "70px",
                                maxWidth: "120px",
                                align: "right",
                              }}
                              src={require("../components/assets/openathens.png")}
                            ></img>
                          </a>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <br></br>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Login;
