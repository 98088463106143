import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./App/context/auth";
import _ from "underscore";

function PrivateRoute({ component: Component, ...rest }) {
  const { authTokens } = useAuth();

  var isLoggedin = false,
    isPro = false;

  if (authTokens) {
    isLoggedin = true;
    if (!_.isUndefined(authTokens.isPro)) {
      isPro = authTokens.isPro > 0 ? true : false;
    }
    if (!_.isUndefined(authTokens.institution_pro)) {
      if (authTokens.institution_pro > 0) isPro = true;
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} isPro={isPro} />}
    />
  );
}

export default PrivateRoute;
