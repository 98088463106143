import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13.61 15.81"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          x="-125.45"
          y="239.88"
          width="666.55"
          height="385.12"
          fill="none"
        />
      </clipPath>
    </defs>
    <g>
      <path
        d="M9.88,2.53l2.65,2.63H9.88ZM2.52,2.38H9.06V6h3.69v9H2.52Zm-.85-.76V15.81H13.61V5.16L10,1.62Z"
        fill="#b3b3b3"
        fillRule="evenodd"
      />
      <polygon
        points="9.21 0.79 8.05 0 0 0 0 14.36 0.8 14.36 0.8 0.79 9.21 0.79"
        fill="#b3b3b3"
        fillRule="evenodd"
      />
    </g>
    <g>
      <path
        d="M7.78,8.52c.08,0,.08-.07.08-.13s0-.11-.17-.15H7.25a1.54,1.54,0,0,0-.78.43h.91A1.18,1.18,0,0,0,7.78,8.52Z"
        fill="#b3b3b3"
      />
      <polygon
        points="4.51 8.24 4.1 8.24 4.1 8.67 4.91 8.67 4.51 8.24"
        fill="#b3b3b3"
      />
      <path d="M11,8.35c0,.11,0,.22,0,.32h.41V8.24H11Z" fill="#b3b3b3" />
      <path d="M11.36,9.53h-.62a3,3,0,0,1-.21.44h.83Z" fill="#b3b3b3" />
      <polygon
        points="5.78 9.8 5.75 9.53 4.1 9.53 4.1 9.97 5.8 9.97 5.78 9.8"
        fill="#b3b3b3"
      />
      <polygon
        points="4.1 10.83 4.1 11.26 5.96 11.26 5.91 10.83 4.1 10.83"
        fill="#b3b3b3"
      />
      <path
        d="M8.77,11.26h2.59v-.43H9.67A3.75,3.75,0,0,1,8.77,11.26Z"
        fill="#b3b3b3"
      />
      <polygon
        points="4.1 12.56 5.67 12.56 5.67 12.39 5.67 12.13 4.1 12.13 4.1 12.56"
        fill="#b3b3b3"
      />
      <polygon
        points="8.91 12.39 8.91 12.56 11.36 12.56 11.36 12.13 8.91 12.13 8.91 12.39"
        fill="#b3b3b3"
      />
      <rect x="4.1" y="13.42" width="1.57" height="0.43" fill="#b3b3b3" />
      <rect x="8.91" y="13.42" width="2.45" height="0.43" fill="#b3b3b3" />
      <rect x="4.1" y="4.35" width="4.11" height="0.43" fill="#b3b3b3" />
      <path
        d="M5.62,5.65H4.1v.43h.81A4.33,4.33,0,0,1,5.62,5.65Z"
        fill="#b3b3b3"
      />
      <path d="M11.36,7.37V6.94h-.72a3.25,3.25,0,0,1,.18.43Z" fill="#b3b3b3" />
      <path
        d="M9.6,6.94a2.66,2.66,0,0,0-2.09-.83,3.26,3.26,0,0,0-2.26.83,3.59,3.59,0,0,0-.39.39l0,0,.78.84A2.52,2.52,0,0,1,7.1,7.37a2.34,2.34,0,0,1,.38,0,2,2,0,0,1,.36,0,1,1,0,0,1,.89.87.76.76,0,0,1,0,.15,1.27,1.27,0,0,1,0,.28c-.11.42-.49.72-1.16.86a4,4,0,0,1-.84.1l-.06.06,0,.28.1.86.06.43,0,.21h.89l0-.21,0-.43,0-.25A3.18,3.18,0,0,0,9.36,10a1.81,1.81,0,0,0,.39-.44,1.92,1.92,0,0,0,.31-.86c0-.1,0-.21,0-.32a.41.41,0,0,0,0-.11,2.22,2.22,0,0,0-.2-.87A1.94,1.94,0,0,0,9.6,6.94Z"
        fill="#b3b3b3"
      />
      <polygon
        points="6.55 12.56 6.55 13.42 6.55 13.85 6.55 13.9 8.03 13.9 8.03 13.85 8.03 13.42 8.03 12.56 8.03 12.39 6.55 12.39 6.55 12.56"
        fill="#b3b3b3"
      />
    </g>
  </svg>
);
