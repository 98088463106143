import React from "react";
import _ from "underscore";
import { Link } from "react-router-dom";
import { checkTokensStorage } from "../context/checkTokens";

const ProComponent = ({ isPro = false, handleModal }) => {
  var loginObj = checkTokensStorage();

  return (
    <div className="why-go-pro-content">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pro-graphic">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 156.86 104.44"
              >
                <g>
                  <path
                    d="M23.43,0A23.42,23.42,0,0,0,0,23.4V46.8A23.41,23.41,0,0,0,23.43,70.2H99.6A23.41,23.41,0,0,0,123,46.8V23.4A23.42,23.42,0,0,0,99.6,0Z"
                    fill="#ffe300"
                    fillRule="evenodd"
                  ></path>
                  <g>
                    <path
                      d="M40,14.19q5.84,0,8.84,3t3,8.42q0,5.41-3,8.44c-2,2-5,3-8.84,3H28.18V52.84H23.05V14.19ZM38.24,32.7q4.38.06,6.41-1.79a6.78,6.78,0,0,0,2-5.3,6.73,6.73,0,0,0-2-5.28q-2-1.81-6.41-1.81H28.18V32.7Z"
                      fillRule="evenodd"
                    ></path>
                    <path
                      d="M61.49,24.85v5.9h.11a12.33,12.33,0,0,1,4.11-5,10.26,10.26,0,0,1,6.16-1.52v4.87a13,13,0,0,0-4.7.76A7.48,7.48,0,0,0,64,32.05,9.21,9.21,0,0,0,62.3,35.6a19.14,19.14,0,0,0-.54,4.79V52.84h-4.6v-28Z"
                      fillRule="evenodd"
                    ></path>
                    <path
                      d="M73.71,33.11a13,13,0,0,1,2.59-4.66,12.3,12.3,0,0,1,4.27-3.11,15.61,15.61,0,0,1,11.71,0,12.19,12.19,0,0,1,4.24,3.11,13,13,0,0,1,2.6,4.66,19.65,19.65,0,0,1,0,11.5,13.08,13.08,0,0,1-2.6,4.63,11.75,11.75,0,0,1-4.24,3.08,16,16,0,0,1-11.71,0,11.86,11.86,0,0,1-4.27-3.08,13.06,13.06,0,0,1-2.59-4.63,19.44,19.44,0,0,1,0-11.5m4.67,10.28a9.59,9.59,0,0,0,1.87,3.3,7.89,7.89,0,0,0,2.78,2,8.4,8.4,0,0,0,6.76,0,7.93,7.93,0,0,0,2.79-2,9.42,9.42,0,0,0,1.86-3.3,14,14,0,0,0,.68-4.52,14,14,0,0,0-.68-4.52A9.75,9.75,0,0,0,92.58,31,8,8,0,0,0,89.79,29,8.51,8.51,0,0,0,83,29,8,8,0,0,0,80.25,31a9.93,9.93,0,0,0-1.87,3.33,14,14,0,0,0-.67,4.52,14,14,0,0,0,.67,4.52"
                      fillRule="evenodd"
                    ></path>
                  </g>
                  <path
                    d="M98.08,68.36A22.71,22.71,0,0,0,103,82.44a31,31,0,0,0,30-30.95V49.05A22.79,22.79,0,0,0,98.08,68.36Z"
                    fill="#ffe300"
                    fillRule="evenodd"
                  ></path>
                  <path d="M138.16,84.07a23.55,23.55,0,0,0,6.13-15.87,23.83,23.83,0,1,0-7.91,17.64l18.7,18.6,1.78-1.77Zm-17.72,5.65a21.5,21.5,0,1,1,21.62-21.5A21.57,21.57,0,0,1,120.44,89.72Z"></path>
                </g>
              </svg>
            </div>
            {!isPro && (
              <>
                <h2>You're one step away from becoming a Pro user</h2>

                <p>
                  The Trip Pro offering takes an already wonderful – and free –
                  version of Trip and makes it even better. With more content,
                  more functionality, no adverts and more, it really makes the
                  Trip experience even better. Institutional and personal
                  subscriptions are available
                  <a href="#" className="btn btn-primary" onClick={handleModal}>
                    click here to see Institutional price list
                  </a>
                  . The table below highlights the main differences between the
                  various offerings.
                </p>

                <p>You can upgrade in one of 2 ways:</p>

                <p>
                  Institutional Subscription (VAT, where applicable, will apply)
                </p>

                <p>
                  If your institution has not already purchased an upgrade, you
                  can find out pricing details here, prices start at $215 per
                  annum. To set this up or ask any further questions please get
                  in touch via our ‘Contact Us‘ page.
                </p>

                <p>
                  Personal Subscription The annual subscription cost is $55 (US
                  Dollars). If applicable, Trip Database will pay VAT at your
                  home countries rate . If you are VAT registered you may claim
                  this back.
                </p>

                <p>Any questions then please get in touch.</p>

                <p>
                  You can purchase your own professional/private subscription by
                  payment card, and this will take effect IMMEDIATELY:
                </p>
                <div className="pro--cta">
                  {loginObj.chkLogin == true && loginObj.chkPro == false ? (
                    <Link
                      className="nav-link"
                      to="/Subscription"
                      eventkey="subscription"
                      data-rb-event-key="/Subscription"
                      className="btn btn-warning"
                    >
                      <span className="pro-label">
                        <i className="fa fa-dollar feather"></i> Upgrade to PRO
                      </span>
                    </Link>
                  ) : (
                    <Link to="/Login" className="btn btn-warning">
                      Login to start PRO purchase
                    </Link>
                  )}
                </div>
              </>
            )}
            <div className="row feature-comparison">
              <div className="col-12">
                <h4>Feature Comparison</h4>
                <div className="table-responsve">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="column-headers-background">
                          <div>
                            <svg
                              id="Layer_1"
                              data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 70.18 35.84"
                            >
                              <rect
                                width="70.18"
                                height="35.84"
                                rx="8"
                                fill="#11b6a8"
                              ></rect>
                              <path
                                d="M56,18.49H49.36a4.13,4.13,0,0,1,.29-1.27,3.51,3.51,0,0,1,.67-1,3.17,3.17,0,0,1,1-.71,3.35,3.35,0,0,1,1.33-.25,3.26,3.26,0,0,1,1.31.25,3.4,3.4,0,0,1,1,.7,3.26,3.26,0,0,1,.69,1A3.93,3.93,0,0,1,56,18.49ZM52.9,25.63a5.26,5.26,0,0,0,3.27-1,4.65,4.65,0,0,0,1.68-2.93H56a2.75,2.75,0,0,1-1,1.7A3.27,3.27,0,0,1,53,24a3.93,3.93,0,0,1-1.64-.31,3.26,3.26,0,0,1-1.14-.84,3.15,3.15,0,0,1-.65-1.21,4.32,4.32,0,0,1-.18-1.44H58A9.52,9.52,0,0,0,57.84,18,6.41,6.41,0,0,0,57,15.8a5.11,5.11,0,0,0-1.64-1.63,4.79,4.79,0,0,0-2.56-.63A5.29,5.29,0,0,0,50.6,14a5.11,5.11,0,0,0-1.72,1.26,5.74,5.74,0,0,0-1.13,1.9,6.94,6.94,0,0,0-.41,2.42,9,9,0,0,0,.4,2.44,5.21,5.21,0,0,0,1,1.93,4.68,4.68,0,0,0,1.71,1.25A5.92,5.92,0,0,0,52.9,25.63ZM44,18.49H37.33a4.13,4.13,0,0,1,.29-1.27,3.35,3.35,0,0,1,.68-1,3,3,0,0,1,1-.71,3.37,3.37,0,0,1,1.34-.25,3.29,3.29,0,0,1,1.31.25,3.35,3.35,0,0,1,1,.7,3.26,3.26,0,0,1,.69,1A3.93,3.93,0,0,1,44,18.49Zm-3.11,7.14a5.22,5.22,0,0,0,3.27-1,4.56,4.56,0,0,0,1.68-2.93H43.94a2.66,2.66,0,0,1-1,1.7,3.25,3.25,0,0,1-2,.56,4,4,0,0,1-1.64-.31,3.26,3.26,0,0,1-1.14-.84,3.29,3.29,0,0,1-.65-1.21,4.91,4.91,0,0,1-.18-1.44H46A9,9,0,0,0,45.81,18,6.41,6.41,0,0,0,45,15.8a5.11,5.11,0,0,0-1.64-1.63,4.79,4.79,0,0,0-2.56-.63,5.21,5.21,0,0,0-2.18.44,5.15,5.15,0,0,0-1.73,1.26,5.92,5.92,0,0,0-1.13,1.9,6.94,6.94,0,0,0-.4,2.42A9.37,9.37,0,0,0,35.71,22a5.36,5.36,0,0,0,1,1.93,4.68,4.68,0,0,0,1.71,1.25A6,6,0,0,0,40.87,25.63Zm-10.14-.25V20.23a7.89,7.89,0,0,1,.22-2,3.89,3.89,0,0,1,.72-1.47A3.07,3.07,0,0,1,33,15.86a5.62,5.62,0,0,1,1.95-.31v-2a4.25,4.25,0,0,0-2.55.62,5.12,5.12,0,0,0-1.7,2.09h-.05V13.8H28.82V25.38Zm-12.26,0V18.06h7.35V16.27H18.47V11.18h8.38V9.39H16.35v16Z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </div>
                        </th>
                        <th className="column-headers-background">
                          <div>
                            <svg
                              id="Layer_1"
                              data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 70.18 35.84"
                            >
                              <rect
                                width="70.18"
                                height="35.84"
                                rx="8"
                                fill="#ffe300"
                              ></rect>
                              <path d="M45.4,24.38a3.37,3.37,0,0,1-2.55-1.13,3.8,3.8,0,0,1-.77-1.37A5.66,5.66,0,0,1,41.8,20a5.7,5.7,0,0,1,.28-1.87,4,4,0,0,1,.77-1.38A3.41,3.41,0,0,1,44,15.91a3.52,3.52,0,0,1,2.8,0,3.32,3.32,0,0,1,1.15.85,4.21,4.21,0,0,1,.78,1.38,6.38,6.38,0,0,1,0,3.74A4,4,0,0,1,48,23.25a3.42,3.42,0,0,1-1.15.84A3.51,3.51,0,0,1,45.4,24.38Zm0,1.66a6.05,6.05,0,0,0,2.43-.46,5,5,0,0,0,1.76-1.28,5.34,5.34,0,0,0,1.07-1.91,8,8,0,0,0,0-4.76,5.43,5.43,0,0,0-1.07-1.93,5.32,5.32,0,0,0-1.76-1.29,6.49,6.49,0,0,0-4.85,0,5.26,5.26,0,0,0-1.77,1.29,5.59,5.59,0,0,0-1.07,1.93,8,8,0,0,0,0,4.76,5.5,5.5,0,0,0,1.07,1.91A5,5,0,0,0,43,25.58,6.11,6.11,0,0,0,45.4,26Zm-10.21-.25V20.64a7.83,7.83,0,0,1,.22-2,3.75,3.75,0,0,1,.72-1.47,3.11,3.11,0,0,1,1.3-.92,5.39,5.39,0,0,1,2-.31v-2a4.3,4.3,0,0,0-2.56.63,5.24,5.24,0,0,0-1.7,2.08h0V14.21H33.29V25.79Zm-9.74-8.33H21.28V11.59h4.17a3.9,3.9,0,0,1,2.65.75,2.77,2.77,0,0,1,.84,2.18,2.82,2.82,0,0,1-.84,2.2A3.8,3.8,0,0,1,25.45,17.46Zm-4.17,8.33V19.25h4.89A4.88,4.88,0,0,0,29.83,18a4.75,4.75,0,0,0,1.24-3.5A4.7,4.7,0,0,0,29.83,11,5,5,0,0,0,26.17,9.8h-7v16Z"></path>
                            </svg>
                          </div>
                        </th>
                        <th className="column-headers-background">
                          <div>
                            <svg
                              id="Layer_1"
                              data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 70.18 35.84"
                            >
                              <rect
                                width="70.18"
                                height="35.84"
                                rx="8"
                                fill="#ffe300"
                              ></rect>
                              <path d="M45.4,24.38a3.37,3.37,0,0,1-2.55-1.13,3.8,3.8,0,0,1-.77-1.37A5.66,5.66,0,0,1,41.8,20a5.7,5.7,0,0,1,.28-1.87,4,4,0,0,1,.77-1.38A3.41,3.41,0,0,1,44,15.91a3.52,3.52,0,0,1,2.8,0,3.32,3.32,0,0,1,1.15.85,4.21,4.21,0,0,1,.78,1.38,6.38,6.38,0,0,1,0,3.74A4,4,0,0,1,48,23.25a3.42,3.42,0,0,1-1.15.84A3.51,3.51,0,0,1,45.4,24.38Zm0,1.66a6.05,6.05,0,0,0,2.43-.46,5,5,0,0,0,1.76-1.28,5.34,5.34,0,0,0,1.07-1.91,8,8,0,0,0,0-4.76,5.43,5.43,0,0,0-1.07-1.93,5.32,5.32,0,0,0-1.76-1.29,6.49,6.49,0,0,0-4.85,0,5.26,5.26,0,0,0-1.77,1.29,5.59,5.59,0,0,0-1.07,1.93,8,8,0,0,0,0,4.76,5.5,5.5,0,0,0,1.07,1.91A5,5,0,0,0,43,25.58,6.11,6.11,0,0,0,45.4,26Zm-10.21-.25V20.64a7.83,7.83,0,0,1,.22-2,3.75,3.75,0,0,1,.72-1.47,3.11,3.11,0,0,1,1.3-.92,5.39,5.39,0,0,1,2-.31v-2a4.3,4.3,0,0,0-2.56.63,5.24,5.24,0,0,0-1.7,2.08h0V14.21H33.29V25.79Zm-9.74-8.33H21.28V11.59h4.17a3.9,3.9,0,0,1,2.65.75,2.77,2.77,0,0,1,.84,2.18,2.82,2.82,0,0,1-.84,2.2A3.8,3.8,0,0,1,25.45,17.46Zm-4.17,8.33V19.25h4.89A4.88,4.88,0,0,0,29.83,18a4.75,4.75,0,0,0,1.24-3.5A4.7,4.7,0,0,0,29.83,11,5,5,0,0,0,26.17,9.8h-7v16Z"></path>
                            </svg>
                            <span>Institutional</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Easy to use</td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="s0 softmerge">
                          <div className="softmerge-inner">
                            Tens of thousands of systematic reviews
                          </div>
                        </td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Largest collection of clinical guidelines</td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>PICO search</td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="s0 softmerge">
                          SmartSearch showing 3 related articles
                        </td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="s0 softmerge">
                          Email alerts for new evidence of interest
                        </td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Answer engine – instant answers</td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Large collection of grey literature</td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Easy to use search refinement tool</td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Easy selection of RCTs</td>
                        <td>
                          <i className="fa fa-check fa-teal"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="s1 softmerge">
                          Hundreds of thousands of systematic reviews
                        </td>
                        <td className="s2"></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Over 175,000 ongoing clinical trials</td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Massive database of medical images</td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Tens of thousands of clinical videos</td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Link to over a million full-text articles</td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="s1 softmerge">
                          <div className="softmerge-inner">
                            Export links to reference management software
                          </div>
                        </td>
                        <td className="s2"></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>No adverts</td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Advanced search</td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Filter results by clinical area</td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="s1 softmerge">
                          Article views, see which articles are most popular
                        </td>
                        <td className="s2"></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="s1 softmerge">
                          SmartSearch showing unlimited related articles
                        </td>
                        <td className="s2"></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td className="s1 softmerge">
                          Link out to your organisation full-text subscription
                          journals
                        </td>
                        <td className="s3"></td>
                        <td className="s2"></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>No need to login to get Pro features</td>
                        <td></td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Institutional usage stats</td>
                        <td></td>
                        <td></td>
                        <td>
                          <i className="fa fa-check fa-yellow"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="pro--cta">
              {loginObj.chkLogin == true && loginObj.chkPro == false ? (
                <Link
                  className="nav-link"
                  to="/Subscription"
                  eventkey="subscription"
                  data-rb-event-key="/Subscription"
                  className="btn btn-warning"
                >
                  <span className="pro-label">
                    <i className="fa fa-dollar feather"></i> Upgrade to PRO
                  </span>
                </Link>
              ) : (
                <Link to="/Login" className="btn btn-warning">
                  Login to start PRO purchase
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProComponent;
