import React from "react";
import _ from "underscore";

import { Button, Modal, Row, Col } from "react-bootstrap";
const errorMdl = ({ error, show, setErrorShow }) => {
  return (
    <Modal show={show} onHide={(e) => setErrorShow(false)} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title className="text-danger">
          <i className="fa fa-times-circle text-danger"></i> Error
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <i className="fa fa-file"></i>{" "}
            {!_.isUndefined(error) ? error.message : ""}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={(e) => setErrorShow(false)}>
          <i className="fa fa-times"></i> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default errorMdl;
