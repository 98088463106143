import React from "react";
import { Nav, Row } from "react-bootstrap";
import "../components/assets/css/SidebarAdmin.css";

const SidebarAdmin = (props) => {
  return (
    <Row className="ml-0 mr-1">
      <Nav
        className="sidebar-sticky col-md-12 d-none d-md-block bg-light sidebar"
        activeKey="/"
        //onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      >
        <Nav.Item>
          <Nav.Link href="/Admin">
            <i className="fa fa-home"></i> Dashboard
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/Admin/Users" eventKey="link-users">
            <i className="fa fa-users"></i> Users
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/Admin/Publications" eventKey="link-publications">
            <i className="fa fa-book"></i> Publications
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link href="/Admin/Emails" eventKey="link-emails">
            <i className="fa fa-envelope"></i> Email Broadcast
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/Admin/Settings" eventKey="link-settings">
            <i className="fa fa-university"></i> Institutions
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Row>
  );
};

export default SidebarAdmin;
