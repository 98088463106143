import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "underscore";
const { sanatizeBooleans } = require("./sanatizeBooleans");
const axios = require("axios");

const ResultSuggestions = ({
  props,
  keywords,
  qry,
  currentParams,
  totalArticles,
}) => {
  const [suggestionsdata, setSuggestions] = useState([]);

  const parseXML = async (xml) => {
    let obj = {};
    let tagRegex = /<([^\/>]+)>|<\/([^>]+)>|([^<]+)(?=<|$)/g;
    let match;
    let current = obj;
    let stack = [];

    while ((match = tagRegex.exec(xml)) !== null) {
      if (match[1]) {
        // Opening tag
        let tagName = match[1].trim();
        let newObject = {};
        if (!current[tagName]) {
          current[tagName] = newObject;
        } else if (Array.isArray(current[tagName])) {
          current[tagName].push(newObject);
        } else {
          current[tagName] = [current[tagName], newObject];
        }
        stack.push(current);
        current = newObject;
      } else if (match[2]) {
        // Closing tag
        current = stack.pop();
      } else if (match[3] && match[3].trim()) {
        // Text node
        current["_text"] = match[3].trim();
      }
    }

    return obj;
  };
  //  const sanatizeBooleans = (param) => {
  //    //  return param
  //    //    .toLowerCase()
  //    //    .replace(/ not\(/gi, " NOT (")
  //    //    .replace(/ or\(/gi, " OR (")
  //    //    .replace(/ and\(/gi, " AND (")
  //    //    .replace(/\)not /gi, ") NOT ")
  //    //    .replace(/\)or /gi, ") OR ")
  //    //    .replace(/\)and /gi, ") AND ")
  //    //    .replace(/ or /gi, " OR ")
  //    //    .replace(/ not /gi, " NOT ")
  //    //    .replace(/ and /gi, " AND ")
  //    //    .replace(/“/gi, '"')
  //    //    .replace(/”/gi, '"')
  //    //    .trim();

  //    return param.replace(
  //      /\b(not|and|or)\b(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/gi,
  //      (match) => match.toUpperCase()
  //    );
  //  };
  const getSuggestions = async () => {
    let eUtil_Spellcheck_API =
      "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/espell.fcgi?db=pubmed&term=$SEARCH_TERM".replace(
        "$SEARCH_TERM",
        keywords
      );

    axios
      .get(eUtil_Spellcheck_API)

      .then(async (res) => {
        let data = null;
        if (res.status === 200) {
          if (!_.isUndefined(res.data)) {
            data = await parseXML(res.data);
            if (
              !_.isUndefined(data['?xml version="1.0"?']) &&
              !_.isUndefined(
                data['?xml version="1.0"?']["eSpellResult"]["CorrectedQuery"]
              )
            ) {
              data =
                data['?xml version="1.0"?']["eSpellResult"]["CorrectedQuery"][
                  "_text"
                ];
            } else data = null;
          }
          data = data ? sanatizeBooleans(data) : data;
          if (data && data !== keywords) setSuggestions([data]);
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  useEffect(() => {
    if (keywords) {
      getSuggestions();
    }
  }, []);
  const handleSearch = (event, criteria) => {
    const store = require("store");
    store.set("key", "standardsearch");

    var prepare_qs = criteria
      ? "?search_type=standard&criteria=" + criteria
      : "";
    props.history.push({
      pathname: "/Searchresult",
      search: prepare_qs,
      state: criteria ? { search_type: "standard", criteria: criteria } : "",
    });
    event.preventDefault();
  };

  // const handleLatest = (e) => {
  //   e.preventDefault();
  //   props.history.push({
  //     pathname: "/Top/" + qry,
  //     search: "?criteria=" + qry + "&search_type=standard",
  //     state: {
  //       criteria: qry,
  //       search_type: "standard",
  //     },
  //   });
  // };
  // const sanitize = (str) => {
  //   return str.replace(/”/gi, '"').replace(/%20/gi, " ").replace(/%/gi, "");
  // };
  return (
    <div className="result-filter-bar results-filters">
      {!_.isEmpty(suggestionsdata) && totalArticles && totalArticles < 50 && (
        <ul>
          <li>
            <h6
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              Did you mean:
            </h6>
          </li>
          {suggestionsdata.map((suggestion, idx) => (
            <li key={idx}>
              <a
                href={
                  "/Searchresult?search_type=standard&criteria=" + suggestion
                }
                onClick={(e) => handleSearch(e, suggestion)}
              >
                {!_.isUndefined(suggestion) ? suggestion : ""}{" "}
              </a>
            </li>
          ))}
        </ul>
      )}

      {/* {!_.isUndefined(currentParams.search_type) &&
        currentParams.search_type == "standard" && (
          <ul>
            <li>
              <Link onClick={handleLatest} to={"/Top/" + sanitize(qry)}>
                <span>Latest and Greatest</span>
              </Link>
            </li>
          </ul>
        )}
      )} */}
    </div>
  );
};

export default ResultSuggestions;
