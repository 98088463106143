import React, { useState } from "react";
import _ from "underscore";
// import { Link } from "react-router-dom";
import checkCookies from "../context/checkCookies";

const CookieBar = ({ isPro }) => {
  const [cookieDipslay, setCookieDisplay] = useState(true);
  return (
    <>
      {cookieDipslay && !checkCookies.getCookies() && (
        <div className="CookieConsent">
          <div className="type-small">
            By using this site you agree to our terms and conditions (see{" "}
            <a href="/Terms">'Terms of Use'</a>,{" "}
            <a href="/Privacy">'Privacy Policy'</a> and{" "}
            <a href="/Acceptableuse">'Acceptable Use'</a>). Our site also uses
            cookies, both essential and non-essential (see{" "}
            <a href="/Cookies">Cookie Policy</a>).
          </div>
          <div className="">
            <button
              className="btn btn-primary type-small"
              id="rcc-confirm-button"
              aria-label="Accept cookies"
              onClick={(e) => {
                setCookieDisplay(false);
                checkCookies.setCookies(true);
                e.preventDefault();
              }}
            >
              I understand
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBar;
