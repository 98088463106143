import React, { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown, Badge } from "react-bootstrap";
import _ from "underscore";
import { useAuth } from "../context/auth";

const store = require("store");
const axios = require("axios");

const NavigationBarAdmin = (props) => {
  const [fullname, setFullname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [isLogin, setLogin] = useState(false);
  const [isAdmin, setAdminUser] = useState(false);

  const { authTokens, setAuthTokens } = useAuth();

  async function logout() {
    setAuthTokens();

    axios
      .get(
        _.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL
          : "" + "/Auth/Logout",
        {
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          // props.history.push({
          //   pathname: "/Login",
          //   state: {
          //     withCredentials: true,
          //   },
          // });
        } else {
          const error = new Error(res.error_message);
          // setErrors(res.error_message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleLogout = (e) => {
    logout();
    setLogin(false);
    e.preventDefault();
  };

  useEffect(() => {
    //var isPro = false;
    if (authTokens) {
      var getdata = _.findWhere(authTokens.roles, {
        role: "Admin",
      });
      if (getdata) {
        setAdminUser(1);
      }
      setFullname(
        !_.isUndefined(authTokens.firstname)
          ? authTokens.firstname + " " + authTokens.lastname
          : ""
      );
      setLogin(true);
    }
    //   fetchData();
  }, [isLogin]);
  var Login = "Login";
  return (
    <Navbar bg="info" variant="dark" collapseOnSelect expand="lg">
      <Navbar.Brand href="/Admin">Trip (CMS)</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav>
          {!isLogin ? (
            <Nav.Link href="/Registration">
              <i className="fa fa-user-plus"></i> Sign Up
            </Nav.Link>
          ) : (
            ""
          )}

          <NavDropdown
            //  drop="down"
            className="dropdown-menu-right pull-right"
            title={
              <span>
                <i className="fa fa-user fa-fw text-info"></i>
                {isLogin ? fullname : Login}
                {isAdmin ? <Badge variant="success"> Admin </Badge> : ""}
              </span>
            }
            id="collasible-nav-dropdown"
          >
            {!isLogin ? (
              <NavDropdown.Item href="/Admin/Login">
                <i className="fa fa-sign-in"></i> Sign in
              </NavDropdown.Item>
            ) : (
              ""
            )}
            {isLogin ? (
              <NavDropdown.Item href="/Changepassword">
                <i className="fa fa-lock fa-fw"></i> Change Password
              </NavDropdown.Item>
            ) : (
              ""
            )}

            <NavDropdown.Item
              eventKey={6}
              href="#"
              onClick={(e) => handleLogout(e)}
            >
              <i className="fa fa-power-off"></i> Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBarAdmin;
