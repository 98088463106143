import React from "react";
import _ from "underscore";

import { Button, Modal, Row, Col } from "react-bootstrap";
const infoMdl = ({ info, show, setInfoShow }) => {
  var info_default = "Information";
  return (
    <Modal show={show} onHide={(e) => setInfoShow(false)} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title className="text-info">
          <i className="fa fa-info-circle text-info"></i>{" "}
          {info.title ? info_default : info.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <i className="fa fa-info-o"></i>{" "}
            {!_.isUndefined(info) ? info.message : ""}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={(e) => setInfoShow(false)}>
          <i className="fa fa-times"></i> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default infoMdl;
