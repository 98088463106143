import React from "react";
import _ from "underscore";

import { Button, Modal, Row, Col } from "react-bootstrap";
const deleteMdl = ({ deletedata, deleteshow, setDeleteShow, handleDelete }) => {
  return (
    <Modal
      show={deleteshow}
      onHide={(e) => setDeleteShow(false)}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-danger">
          <i className="fa fa-times-circle text-danger"></i>
          {!_.isUndefined(deletedata.info) ? deletedata.info : "Delete"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <i className="fa fa-file"></i>{" "}
            {!_.isUndefined(deletedata.info)
              ? deletedata.info
              : "Are you sure to delete "}
            <b>
              {" "}
              {!_.isUndefined(deletedata.description)
                ? deletedata.description
                : ""}{" "}
            </b>
            ?
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={(e) => setDeleteShow(false)}>
          No
        </Button>
        <Button
          variant="danger"
          data-value={deletedata.id}
          data-action={deletedata.action}
          onClick={handleDelete}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default deleteMdl;
