import React from "react";
import Pyramid1Svg from "./Pyramid1";
import Pyramid2Svg from "./Pyramid2";
import Pyramid3Svg from "./Pyramid3";
import Pyramid4Svg from "./Pyramid4";
import Pyramid5Svg from "./Pyramid5";
function getSvg(grade) {
  switch (grade) {
    case 1:
      return <Pyramid1Svg></Pyramid1Svg>;
      break;
    case 2:
      return <Pyramid2Svg></Pyramid2Svg>;

      break;
    case 3:
      return <Pyramid3Svg></Pyramid3Svg>;

      break;
    case 4:
      return <Pyramid4Svg></Pyramid4Svg>;
      break;
    case 5:
      return <Pyramid5Svg></Pyramid5Svg>;
      break;
    default:
      return;
  }
}
export default (props) => {
  return getSvg(props.grade);
};
